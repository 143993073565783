import Raven from 'raven-js';
import { fetchSearchResults } from '../data/api/googleProgrammableSearchClient';
import { CLEAR_SEARCH, SEARCH_FAILED, SEARCH_STARTED, SEARCH_SUCCEEDED, SET_SEARCH_QUERY } from './ActionTypes';
export const setSearchQuery = ({
  query
}) => ({
  type: SET_SEARCH_QUERY,
  query
});
const searchStarted = () => ({
  type: SEARCH_STARTED
});
const searchSucceeded = ({
  items = [],
  start
}) => {
  return {
    type: SEARCH_SUCCEEDED,
    items,
    start
  };
};
const searchFailed = error => ({
  type: SEARCH_FAILED,
  error
});
export const clearSearch = () => ({
  type: CLEAR_SEARCH
});
export const search = (query, locale, offset) => dispatch => {
  dispatch({
    type: SEARCH_STARTED
  });
  fetchSearchResults(query, locale, offset).then(resp => {
    dispatch(searchSucceeded(resp));
  }).catch(error => {
    dispatch(searchFailed(error));
    Raven.captureException(error);
  });
};