// @ts-expect-error Untyped
import { noAuthClient } from 'developer-docs-shared-components/lib/utils/httpClient';
import { FETCH_COOKIE_ECHO_FAILED, FETCH_COOKIE_ECHO_STARTED, FETCH_COOKIE_ECHO_SUCCEEDED, FETCH_USER_GATES_STARTED, FETCH_USER_GATES_FAILED, FETCH_USER_GATES_SUCCEEDED, SET_DOCS_VERSION, SET_HAS_INITIALIZED_DOCS_VERSION } from './ActionTypes';
export const setDocsVersion = version => ({
  type: SET_DOCS_VERSION,
  value: version
});
export const setHasInitializedDocsVersion = value => ({
  type: SET_HAS_INITIALIZED_DOCS_VERSION,
  value
});
const fetchCookieEchoStarted = () => ({
  type: FETCH_COOKIE_ECHO_STARTED
});
const fetchCookieEchoSucceeded = data => ({
  type: FETCH_COOKIE_ECHO_SUCCEEDED,
  payload: data
});
const fetchCookieEchoFailed = error => ({
  type: FETCH_COOKIE_ECHO_FAILED,
  payload: error
});
const fetchUserGatesStarted = () => ({
  type: FETCH_USER_GATES_STARTED
});
const fetchUserGatesSucceeded = data => ({
  type: FETCH_USER_GATES_SUCCEEDED,
  payload: data
});
const fetchUserGatesFailed = error => ({
  type: FETCH_USER_GATES_FAILED,
  payload: error
});
export const fetchUserGates = () => dispatch => {
  dispatch(fetchUserGatesStarted());
  return noAuthClient.get('/login-verify/user-info').then(data => {
    const combinedGates = data.enabledGates.concat(data.enabledV3Gates);
    dispatch(fetchUserGatesSucceeded(combinedGates));
  }, error => {
    dispatch(fetchUserGatesFailed(error));
  });
};
export const fetchCookieEcho = () => dispatch => {
  dispatch(fetchCookieEchoStarted());
  return noAuthClient.get('/cookie-echo/v1/user').then(data => {
    dispatch(fetchCookieEchoSucceeded(data));
    dispatch(fetchUserGates());
  }, error => {
    dispatch(fetchCookieEchoFailed(error));
    dispatch(fetchUserGatesFailed(new Error('No logged in user')));
  });
};