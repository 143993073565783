// @ts-expect-error Untyped
import RequestStatus from 'developer-docs-shared-components/lib/models/RequestStatus';
import { FETCH_COOKIE_ECHO_FAILED, FETCH_COOKIE_ECHO_STARTED, FETCH_COOKIE_ECHO_SUCCEEDED, FETCH_USER_GATES_FAILED, FETCH_USER_GATES_STARTED, FETCH_USER_GATES_SUCCEEDED, SET_DOCS_VERSION, SET_HAS_INITIALIZED_DOCS_VERSION } from '../actions/ActionTypes';
export const DEFAULT_USER_STATE = {
  docsVersion: '2020-10',
  hasInitializedDocVersion: false,
  loggedIn: {
    status: RequestStatus.uninitialized,
    error: false
  },
  userGates: {
    status: RequestStatus.uninitialized,
    error: false,
    gates: []
  }
};
const userState = (state = DEFAULT_USER_STATE, action) => {
  switch (action.type) {
    case SET_DOCS_VERSION:
      return Object.assign({}, state, {
        docsVersion: action.value
      });
    case SET_HAS_INITIALIZED_DOCS_VERSION:
      return Object.assign({}, state, {
        hasInitializedDocVersion: action.value
      });
    case FETCH_COOKIE_ECHO_STARTED:
      return Object.assign({}, state, {
        loggedIn: {
          status: RequestStatus.pending,
          error: false
        }
      });
    case FETCH_COOKIE_ECHO_SUCCEEDED:
      return Object.assign({}, state, {
        loggedIn: {
          status: RequestStatus.succeeded,
          error: false,
          data: action.payload
        }
      });
    case FETCH_COOKIE_ECHO_FAILED:
      return Object.assign({}, state, {
        loggedIn: {
          status: RequestStatus.failed,
          error: true
        }
      });
    case FETCH_USER_GATES_STARTED:
      return Object.assign({}, state, {
        userGates: {
          status: RequestStatus.pending,
          error: false,
          gates: []
        }
      });
    case FETCH_USER_GATES_SUCCEEDED:
      return Object.assign({}, state, {
        userGates: {
          status: RequestStatus.succeeded,
          error: false,
          gates: action.payload
        }
      });
    case FETCH_USER_GATES_FAILED:
      return Object.assign({}, state, {
        userGates: {
          status: RequestStatus.failed,
          error: true,
          gates: []
        }
      });
    default:
      return state;
  }
};
export default userState;