import { versionInfo } from 'developer-docs-source/navigation';
import I18n from 'I18n';
export const SharedLegacyDocsAlertText = () => I18n.text('developer-docs-shared-components.legacyHeaderContent.sharedLegacyDocsAlertText');
export const SharedDeprecatedDocsAlertText = () => I18n.text('developer-docs-shared-components.legacyHeaderContent.sharedDeprecatedDocsAlertText');
export const legacyDocsIntroContent = () => ({
  text: SharedLegacyDocsAlertText(),
  type: 'warning'
});
export const legacyDocsDeprecatedContent = () => ({
  text: SharedDeprecatedDocsAlertText(),
  type: 'warning'
});
export const getVersionUrlOfDefaultSelected = versions => {
  if (!versions) return '';
  const version = versions.find(v => v.isDefaultSelected);
  return version ? `/${version.versionName}` : '';
};
export const generateUrlWithVersionFromBaseUrl = url => {
  var _versionInfo$url;
  return `/api${url}${getVersionUrlOfDefaultSelected(((_versionInfo$url = versionInfo[url]) === null || _versionInfo$url === void 0 ? void 0 : _versionInfo$url.legacyVersions) || [])}`;
};