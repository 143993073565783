import { sanitize } from 'sanitize-text/sanitizers/Sanitize';
import { config } from 'sanitize-text/sanitizers/SanitizeConfiguration';
import { getPathNames } from 'developer-docs-shared-components/utils/urls';
import { clearSearch, search, setSearchQuery } from '../../../actions/SearchActions';
import { SEARCH, SEARCH_ACTIONS, SEARCH_ORIGINS } from '../../../tracking/trackerActions';
import { getUsageTracker } from '../../../utils/usage-tracker/index';
import { Paths } from '../../RoutesPublic';
import { getRouterAwarePathFromProxyUrl } from 'developer-docs-shared-components/utils/urls';

/**
 * Results per page, set by Google.
 */
export const resultPerPage = 10;
export const getSanitizedAndEncodedQuery = query => {
  if (!query) return query;
  const sanitizedQuery = sanitize(query, config.TEXTONLY);
  const sanitizedAndEncodedQuery = encodeURIComponent(sanitizedQuery);
  return sanitizedAndEncodedQuery;
};
export const getSearchResultsPageUrl = (query, pageValue) => {
  if (!query) {
    return Paths.SEARCH;
  }
  const updatedPath = `${Paths.SEARCH}?q=${getSanitizedAndEncodedQuery(query)}${pageValue ? `&page=${pageValue}` : ''}`;
  return updatedPath;
};
export const getOffsetFromPage = (page = 1) => (page - 1) * resultPerPage + 1;

/**
 * Given a Google-indexed `pagemap.metatags` object key for a page, returns its
 * value.
 */
export function getPageMapValueFromTagName(result, key) {
  const {
    pagemap: {
      metatags: [{
        [key]: tagValue
      }]
    }
  } = result;
  if (!tagValue && key === 'developer-docs-ui:category:primary') {
    // Use primary directory as fallback if meta tag not set - temporary until all metadata is indexed by Google
    const routerHref = getRouterAwarePathFromProxyUrl(result.link);
    const {
      primaryDir
    } = getPathNames(routerHref);
    return primaryDir;
  }
  return tagValue;
}

/**
 * The type of page as displayed in search results. Currently results render
 * differently based on only the primary directory to categorize results as
 * "REFERENCE" or "GUIDES", where "GUIDES" is the default. This categorization
 * can be refined in future.
 */
export const getPageTypeFromLink = link => {
  const {
    primaryDir
  } = getPathNames(link);
  return primaryDir.toUpperCase();
};
export const searchSanitizedAndEncodedQuery = (query, locale, dispatch, isOnType, page) => {
  if (!query) {
    dispatch(clearSearch());
    return;
  }
  const sanitizedQuery = getSanitizedAndEncodedQuery(query);
  dispatch(setSearchQuery({
    query
  }));
  dispatch(search(sanitizedQuery, locale, getOffsetFromPage(page)));
  getUsageTracker().track(SEARCH, {
    action: SEARCH_ACTIONS.SUBMITTED,
    origin: isOnType ? SEARCH_ORIGINS.DROPDOWN_SEARCH : SEARCH_ORIGINS.STANDARD_SEARCH,
    searchTerm: query
  });
};