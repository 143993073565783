import { protectedDocs } from './protected';
/**
 *  Dynamically imports a specific MDX file
 * @param locale the locale to import, e.g. en-us
 * @param path  the path to the file, e.g. /top-level-dir/file-name
 * @returns  the imported MDX file
 */
export async function importMdx(locale, path) {
  return await import( /* webpackChunkName: "[request]" */
  `./${locale}${path}.mdx`);
}

/**
 * Takes a list of user gates and returns the protected docs that are available to them
 * @param gates
 * @returns
 */
export function getProtectedDocs(gates) {
  const keys = Object.keys(protectedDocs);
  const availableGates = keys.filter(key => gates.includes(key));
  if (availableGates.length === 0) {
    return;
  } else {
    let urlKeys = {};

    // For each gate, merge the URL keys with that gate.
    availableGates.forEach(gate => {
      // First pass can just copy
      if (Object.keys(urlKeys).length === 0) {
        urlKeys = protectedDocs[gate];
      } else {
        // For each additional gate, find the right top level dir and add routes to
        // the existing URL keys
        const keysCurrent = Object.keys(urlKeys['en-us']);
        const keysNew = Object.keys(protectedDocs[gate]['en-us']);
        const commonTopLevelDirs = keysCurrent.filter(key => keysNew.includes(key));
        const uncommonTopLevelDirs = keysNew.filter(key => !keysCurrent.includes(key));
        for (const commonTopLevelDir of commonTopLevelDirs) {
          urlKeys['en-us'][commonTopLevelDir] = urlKeys['en-us'][commonTopLevelDir].concat(protectedDocs[gate]['en-us'][commonTopLevelDir]);
        }
        for (const uncommonTopLevelDir of uncommonTopLevelDirs) {
          urlKeys['en-us'][uncommonTopLevelDir] = protectedDocs[gate]['en-us'][uncommonTopLevelDir];
        }
      }
    });
    return Object.assign({}, urlKeys);
  }
}