export const stringToId = str => encodeURIComponent(str.toLocaleLowerCase()
// replace :: and ' with space, which will be replaced with -
.replace(/::|'/g, ' ')
// remove : and ()
.replace(/[:()]/g, '').split(' ').join('-'));

/**
 * The absolute base path off `(br\.)?developers\.hubspot\.(com|es|fr|jp|de){1}`.
 * @todo cleanup after migration
 */
export const proxiedSubdomainBasePath = '/docs';

/**
 * Gets path names, excluding the proxied subdomain base directory.
 *
 * @example
 * ```tsx
 * const { pathname } = useLocation();
 * const { primaryDir, secondaryDir } = getPathNames(pathname);
 * ```
 */
export const getPathNames = pathname => {
  const computedPathname = pathname.replace(proxiedSubdomainBasePath, '');
  const [, primaryDir, secondaryDir] = computedPathname.split('/');
  return {
    primaryDir,
    secondaryDir
  };
};

/**
 * Regex for the proxy as returned in Google search results.
 */
const GOOGLE_RES_DOMAIN_REGEX = /(https:\/\/)?(br\.)?developers\.hubspot\.(com|de|es|fr|jp)(\/(docs))\/?/g;

/**
 * Get router-aware path from proxy URL.
 */
export const getRouterAwarePathFromProxyUrl = fullUrl => `/${fullUrl.replace(GOOGLE_RES_DOMAIN_REGEX, '')}`;