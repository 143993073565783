import UIExpandableSection from 'UIComponents/accordion/UIExpandableSection';
import styled from 'styled-components';

/**
 * Shared expandable section-related styled components.
 */

/**
 * The header of the `UIExpandableSection`.
 * @ts-expect-error defaultProps is defined */
export const ExpandableHeader = styled(UIExpandableSection.defaultProps.Header).withConfig({
  displayName: "ExpandableComponents__ExpandableHeader",
  componentId: "hy1y1c-0"
})(["border:none;padding:0;&::after{content:none;}"]);

/**
 * The opener, which wraps the `Caret` and `Title` of the
 * `UIExpandableSection`.
 * @ts-expect-error defaultProps is defined */
export const ExpandableOpener = styled(UIExpandableSection.defaultProps.Opener).withConfig({
  displayName: "ExpandableComponents__ExpandableOpener",
  componentId: "hy1y1c-1"
})(["flex-direction:row-reverse;justify-content:space-between;padding:0;"]);