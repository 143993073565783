import I18n from 'I18n';
import * as SimpleDate from 'foundations-components/transitional/utils/SimpleDate';
export const getStateFromIsoDate = iso => {
  if (!iso) {
    return {
      date: undefined,
      time: undefined,
      timezone: ''
    };
  }
  const m = I18n.moment(iso);
  const startOfDay = m.clone().startOf('day');
  return {
    date: SimpleDate.fromMoment(m),
    // SimpleDate representing day
    time: m.diff(startOfDay, 'minutes'),
    // Minutes since midnight
    timezone: m.format('Z')
  };
};

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export const getIsoDateFromState = ({
  date,
  time,
  timezone
}) => {
  const m = I18n.moment({
    year: date.year,
    month: date.month,
    day: date.date,
    hour: time / 60,
    minute: time % 60,
    second: 0
  }).utcOffset(timezone, true);
  return m.toISOString();
};

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export const getStateFromDateString = dateString => {
  const m = I18n.moment(dateString, 'YYYY-MM-DD');
  return {
    date: SimpleDate.fromMoment(m)
  };
};

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export const getDateStringFromState = ({
  date
}) => {
  return SimpleDate.toFormattedString(date, 'YYYY-MM-DD');
};