import { ctxKeyToPath, API_SPEC_DOCS } from 'developer-docs-mdx-components/utils/urls';
import { getLabel } from 'developer-docs-source/labels/index';

/**
 * The shared absolute path for all routes within a secondary nav section.
 */

/**
 * A UIBadge.
 */

/**
 * `NavItem` is a generic term for either a page link or folder containing
 * child items.
 */

/**
 * A nav item with label and child items array. Folders cannot have badges;
 * only links can.
 */

/**
 * Secondary nav config, shown on the left sidebar at desktop.
 * @example 'API Endpoints' within the 'API & Reference' primary nav
 */

/**
 * Primary nav item shown at the top-level, in the header. Has secondary nav
 * as a child.
 *
 * @example 'API & Reference'
 */

export const allSpecApiReferenceKeys = [...API_SPEC_DOCS].map(key => ctxKeyToPath(key));
export function assertBadgeIsValid(badge) {
  if (!badge) return;
  if (badge !== 'NEW' && badge !== 'BETA') {
    throw new Error(`Invalid badge: ${badge}`);
  }
}
export const addLabelAndVerifyBadge = item => {
  if ('items' in item) {
    return Object.assign({}, item, {
      label: getLabel(item.url),
      items: item.items.map(childItem => addLabelAndVerifyBadge(childItem))
    });
  } else {
    if (item.external) {
      return {
        label: getLabel(item.key),
        url: item.url
      };
    }

    // check to see if the item badge is supported
    const badge = item.badge;
    assertBadgeIsValid(badge);
    const label = getLabel(item.url);
    return Object.assign({}, item, {
      badge,
      url: item.url,
      label
    });
  }
};