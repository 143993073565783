import { getLabel, labelExists } from 'developer-docs-source/labels/index';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { apiEndpointsNavConfig } from '../config/nav/reference/apiEndpoints';
import { apiGuidesNavConfig } from '../config/nav/guides/apiGuides';
import { cmsDevelopmentNavConfig } from '../config/nav/guides/cmsDevelopment';
import { getStartedNavConfig } from '../config/nav/getStarted/getStarted';
import { crmCustomizationNavConfig } from '../config/nav/guides/crmCustomization';
import { appsNavConfig } from '../config/nav/guides/apps';
import { getPathNames } from 'developer-docs-shared-components/utils/urls';
import { uiComponentsNavConfig } from '../config/nav/reference/uiComponents';
import { cmsReferenceNavConfig } from '../config/nav/reference/cmsReference';
import { getBreadcrumbLabelsFromPath, getKeyFromUrl } from '../utils/breadcrumbs';

/**
 * @param config takes in the the title of the endpoint or guide which will be
 * the last entry in the breadcrumb matches static breadcrumbs defined for the
 * current route in navigation.ts keyToNavData, determines the current route
 * using react router and the supplied title and pageType (guide vs api
 * reference page)
 * @returns an array with the breadcrumbs for the current route
 */
export const useGetBreadcrumbsByUrl = (config, pathname, includePageTitle) => {
  const location = useLocation();

  /**
   * Maps absolute base path for this nav section (typically consisting of
   * two levels in the format `/PRIMARY/SECONDARY`) to the section's link and
   * text to render as breadcrumbs.
   */
  const breadCrumbs = useMemo(() => {
    const apiPathByRoute = [cmsDevelopmentNavConfig(), apiGuidesNavConfig(), apiEndpointsNavConfig(), getStartedNavConfig(), crmCustomizationNavConfig(), appsNavConfig(), uiComponentsNavConfig(), cmsReferenceNavConfig()].reduce((acc, {
      commonPath,
      sectionLabel: headerLabel
    }) => {
      acc[commonPath] = {
        link: commonPath,
        name: headerLabel
      };
      return acc;
    }, {});
    const breadcrumbPath = getKeyFromUrl(pathname || location.pathname);
    const {
      primaryDir,
      secondaryDir
    } = getPathNames(breadcrumbPath);
    const currentPage = config && config.title ? [{
      name: config.title
    }] : [];
    const apiRoute = apiPathByRoute[`/${primaryDir}/${secondaryDir}`] ? apiPathByRoute[`/${primaryDir}/${secondaryDir}`].link : `/${primaryDir}`;
    const matchedPath = apiPathByRoute[apiRoute];
    if (!matchedPath) {
      return [...currentPage];
    }
    const {
      link,
      name
    } = matchedPath;
    const primaryDirs = [{
      link,
      name
    }];
    const pagePath = breadcrumbPath.replace(link, '');
    return [...primaryDirs, ...getBreadcrumbLabelsFromPath(pagePath, primaryDir, secondaryDir), ...currentPage, ...(includePageTitle && labelExists(breadcrumbPath) ? [{
      name: getLabel(breadcrumbPath)
    }] : [])];
  }, [pathname, location.pathname, config, includePageTitle]);
  if (breadCrumbs.length > 1) {
    return breadCrumbs;
  }
  return null;
};