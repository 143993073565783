/* eslint-disable hubspot-dev/no-private-classes */
export const navigation = {
  '/getting-started': {
    items: [{
      position: 1,
      url: '/getting-started/overview',
      label: 'Overview'
    }, {
      position: 1,
      url: '/getting-started/what-to-build',
      label: 'What to build'
    }, {
      position: 3,
      url: '/getting-started/tools-to-help-you-build',
      label: 'Tools to help you build'
    }, {
      url: '/getting-started/account-types',
      label: 'Account types'
    }, {
      label: 'Slack',
      items: [{
        url: '/getting-started/slack/code-of-conduct',
        label: 'Code of conduct'
      }, {
        url: '/getting-started/slack/developer-slack',
        label: 'Developer Slack'
      }],
      dir: 'slack',
      url: '/getting-started/slack',
      overrideUrl: '/getting-started/slack/developer-slack'
    }]
  },
  '/reference/cms': {
    items: [{
      position: 1,
      url: '/reference/cms/overview',
      label: 'Overview'
    }, {
      label: 'Fields',
      items: [{
        position: 1,
        url: '/reference/cms/fields/module-theme-fields',
        label: 'Module and theme fields reference'
      }, {
        url: '/reference/cms/fields/rich-text-editor',
        label: 'Rich text editor'
      }],
      dir: 'fields',
      url: '/reference/cms/fields',
      position: 2
    }, {
      position: 3,
      url: '/reference/cms/forms',
      label: 'Forms'
    }, {
      label: 'HubL',
      items: [{
        position: 1,
        url: '/reference/cms/hubl/overview',
        label: 'Overview'
      }, {
        position: 2,
        url: '/reference/cms/hubl/operators-and-expression-tests',
        label: 'Operators and expression tests'
      }, {
        position: 3,
        url: '/reference/cms/hubl/filters',
        label: 'Filters'
      }, {
        position: 4,
        url: '/reference/cms/hubl/functions',
        label: 'Functions'
      }, {
        position: 5,
        url: '/reference/cms/hubl/loops',
        label: 'Loops'
      }, {
        label: 'Tags',
        items: [{
          position: 1,
          url: '/reference/cms/hubl/tags/standard-tags',
          label: 'Standard tags'
        }, {
          position: 2,
          url: '/reference/cms/hubl/tags/dnd-areas',
          label: 'Drag and drop areas'
        }, {
          position: 3,
          url: '/reference/cms/hubl/tags/deprecated',
          label: 'Deprecated'
        }, {
          position: 4,
          url: '/reference/cms/hubl/tags/related-blog-posts',
          label: 'Related blog posts'
        }],
        dir: 'tags',
        url: '/reference/cms/hubl/tags',
        position: 6
      }, {
        position: 7,
        url: '/reference/cms/hubl/variables',
        label: 'Variables'
      }, {
        position: 8,
        url: '/reference/cms/hubl/quote-variables',
        label: 'Quote template variables',
        badge: 'BETA'
      }, {
        position: 8,
        url: '/reference/cms/hubl/variables-macros-syntax',
        label: 'Variables and macros syntax'
      }, {
        position: 9,
        url: '/reference/cms/hubl/if-statements',
        label: 'If statements'
      }, {
        url: '/reference/cms/hubl/deprecated',
        label: 'Deprecated'
      }],
      dir: 'hubl',
      url: '/reference/cms/hubl',
      position: 4
    }, {
      label: 'Modules',
      items: [{
        url: '/reference/cms/modules/configuration',
        label: 'Configuring a module'
      }, {
        url: '/reference/cms/modules/default-email-modules',
        label: 'Default email modules'
      }, {
        url: '/reference/cms/modules/default-module-versioning',
        label: 'Default module versioning'
      }, {
        url: '/reference/cms/modules/default-modules',
        label: 'Default web modules'
      }, {
        url: '/reference/cms/modules/deprecated',
        label: 'Deprecated'
      }, {
        url: '/reference/cms/modules/export-to-template-context',
        label: 'export_to_template_context'
      }, {
        url: '/reference/cms/modules/files',
        label: 'Module files'
      }, {
        url: '/reference/cms/modules/using-modules-in-templates',
        label: 'Using Modules in Templates'
      }],
      dir: 'modules',
      url: '/reference/cms/modules',
      position: 5
    }, {
      label: 'Serverless functions',
      items: [{
        position: 1,
        url: '/reference/cms/serverless-functions/serverless-functions-in-projects',
        label: 'Built with projects',
        badge: 'NEW'
      }, {
        url: '/reference/cms/serverless-functions/serverless-functions',
        label: 'Built with the design manager'
      }],
      dir: 'serverless-functions',
      url: '/reference/cms/serverless-functions',
      position: 6
    }]
  },
  '/reference/api': {
    items: [{
      position: 0,
      url: '/reference/api/overview',
      label: 'Overview'
    }, {
      label: 'Analytics and events',
      items: [{
        label: 'Cookie banner',
        items: [{
          url: '/reference/api/analytics-and-events/cookie-banner/cookie-banner-api',
          label: 'Cookie banner API'
        }, {
          url: '/reference/api/analytics-and-events/cookie-banner/google-consent-mode',
          label: 'Google Consent Mode'
        }],
        dir: 'cookie-banner',
        url: '/reference/api/analytics-and-events/cookie-banner'
      }, {
        label: 'Custom events',
        items: [{
          url: '/reference/api/analytics-and-events/custom-events/custom-event-completions',
          label: 'Custom event completions'
        }, {
          url: '/reference/api/analytics-and-events/custom-events/custom-event-definitions',
          badge: 'BETA',
          label: 'Custom event definitions'
        }],
        dir: 'custom-events',
        url: '/reference/api/analytics-and-events/custom-events'
      }, {
        url: '/reference/api/analytics-and-events/email-analytics',
        label: 'Email analytics'
      }, {
        url: '/reference/api/analytics-and-events/event-analytics',
        label: 'Event analytics'
      }, {
        url: '/reference/api/analytics-and-events/reporting',
        label: 'Reporting'
      }, {
        url: '/reference/api/analytics-and-events/tracking-code',
        label: 'Tracking code reference'
      }],
      dir: 'analytics-and-events',
      url: '/reference/api/analytics-and-events',
      position: 1
    }, {
      label: 'App management',
      items: [{
        url: '/reference/api/app-management/feature-flags',
        badge: 'BETA',
        label: 'Feature flags'
      }, {
        url: '/reference/api/app-management/oauth',
        label: 'OAuth'
      }, {
        url: '/reference/api/app-management/webhooks',
        label: 'Webhooks'
      }],
      dir: 'app-management',
      url: '/reference/api/app-management',
      position: 2
    }, {
      label: 'Automation',
      items: [{
        url: '/reference/api/automation/create-manage-workflows',
        label: 'Create & manage workflows'
      }, {
        url: '/reference/api/automation/custom-code-actions',
        label: 'Custom code actions'
      }, {
        url: '/reference/api/automation/custom-workflow-actions',
        label: 'Custom workflow actions'
      }, {
        url: '/reference/api/automation/sequences',
        badge: 'BETA',
        label: 'Sequences'
      }],
      dir: 'automation',
      url: '/reference/api/automation',
      position: 3
    }, {
      label: 'CMS',
      items: [{
        label: 'Blogs',
        items: [{
          url: '/reference/api/cms/blogs/blog-authors',
          label: 'Blog authors'
        }, {
          url: '/reference/api/cms/blogs/blog-comments',
          label: 'Blog comments'
        }, {
          url: '/reference/api/cms/blogs/blog-details',
          label: 'Blog details'
        }, {
          url: '/reference/api/cms/blogs/blog-posts',
          label: 'Blog posts'
        }, {
          url: '/reference/api/cms/blogs/blog-tags',
          label: 'Blog tags'
        }, {
          url: '/reference/api/cms/blogs/blog-topics',
          label: 'Blog topics'
        }],
        dir: 'blogs',
        url: '/reference/api/cms/blogs'
      }, {
        url: '/reference/api/cms/content-audit',
        label: 'Content audit'
      }, {
        url: '/reference/api/cms/domains',
        label: 'Domains'
      }, {
        url: '/reference/api/cms/hubdb',
        label: 'HubDB'
      }, {
        url: '/reference/api/cms/layouts',
        label: 'Layouts'
      }, {
        url: '/reference/api/cms/media-bridge',
        label: 'Media bridge'
      }, {
        url: '/reference/api/cms/modules',
        label: 'Modules'
      }, {
        url: '/reference/api/cms/pages',
        label: 'Pages'
      }, {
        url: '/reference/api/cms/site-search',
        label: 'Site search'
      }, {
        url: '/reference/api/cms/source-code',
        label: 'Source code'
      }, {
        url: '/reference/api/cms/templates',
        label: 'Templates'
      }, {
        url: '/reference/api/cms/url-redirects',
        label: 'URL redirects'
      }],
      dir: 'cms',
      url: '/reference/api/cms',
      position: 4
    }, {
      label: 'Conversations',
      items: [{
        url: '/reference/api/conversations/chat-widget-sdk',
        label: 'Chat widget SDK'
      }, {
        url: '/reference/api/conversations/inbox-and-messages',
        label: 'Conversations'
      }, {
        url: '/reference/api/conversations/create-custom-channels',
        badge: 'BETA',
        label: 'Create custom channels'
      }, {
        url: '/reference/api/conversations/visitor-identification',
        label: 'Visitor identification'
      }],
      dir: 'conversations',
      url: '/reference/api/conversations',
      position: 5
    }, {
      label: 'Library',
      items: [{
        url: '/reference/api/library/files',
        label: 'Files'
      }, {
        url: '/reference/api/library/meetings',
        label: 'Meetings'
      }],
      dir: 'library',
      url: '/reference/api/library',
      position: 6
    }, {
      label: 'CRM',
      items: [{
        label: 'Associations',
        items: [{
          url: '/reference/api/crm/associations/association-details',
          label: 'Association details'
        }, {
          url: '/reference/api/crm/associations/associations-schema',
          label: 'Associations schema'
        }],
        dir: 'associations',
        url: '/reference/api/crm/associations',
        position: 1
      }, {
        label: 'Commerce',
        items: [{
          url: '/reference/api/crm/commerce/carts',
          badge: 'NEW',
          label: 'Carts'
        }, {
          url: '/reference/api/crm/commerce/discounts',
          label: 'Discounts'
        }, {
          url: '/reference/api/crm/commerce/fees',
          label: 'Fees'
        }, {
          url: '/reference/api/crm/commerce/invoices',
          label: 'Invoices'
        }, {
          url: '/reference/api/crm/commerce/orders',
          badge: 'NEW',
          label: 'Orders'
        }, {
          url: '/reference/api/crm/commerce/payments',
          label: 'Payments'
        }, {
          url: '/reference/api/crm/commerce/quotes',
          label: 'Quotes'
        }, {
          url: '/reference/api/crm/commerce/subscriptions',
          label: 'Subscriptions'
        }, {
          url: '/reference/api/crm/commerce/taxes',
          label: 'Taxes'
        }],
        dir: 'commerce',
        url: '/reference/api/crm/commerce',
        position: 2
      }, {
        url: '/reference/api/crm/contacts-timeline-embed',
        position: 3,
        label: 'Contacts timeline embed'
      }, {
        label: 'Engagements',
        items: [{
          url: '/reference/api/crm/engagements/engagement-details',
          position: 0,
          label: 'Engagement Details'
        }, {
          url: '/reference/api/crm/engagements/calls',
          label: 'Calls'
        }, {
          url: '/reference/api/crm/engagements/communications',
          badge: 'NEW',
          label: 'Communications'
        }, {
          url: '/reference/api/crm/engagements/email',
          label: 'Email'
        }, {
          url: '/reference/api/crm/engagements/meetings',
          label: 'Meetings'
        }, {
          url: '/reference/api/crm/engagements/notes',
          label: 'Notes'
        }, {
          url: '/reference/api/crm/engagements/postal-mail',
          label: 'Postal mail'
        }, {
          url: '/reference/api/crm/engagements/tasks',
          label: 'Tasks'
        }],
        dir: 'engagements',
        url: '/reference/api/crm/engagements',
        position: 4
      }, {
        label: 'Extensions',
        items: [{
          url: '/reference/api/crm/extensions/calling-sdk',
          label: 'Calling SDK'
        }, {
          url: '/reference/api/crm/extensions/crm-cards',
          label: 'CRM Cards'
        }, {
          url: '/reference/api/crm/extensions/extension-details',
          label: 'Extension details'
        }, {
          url: '/reference/api/crm/extensions/timeline',
          label: 'Timeline'
        }, {
          url: '/reference/api/crm/extensions/video-conferencing',
          label: 'Video conferencing'
        }],
        dir: 'extensions',
        url: '/reference/api/crm/extensions',
        position: 6
      }, {
        url: '/reference/api/crm/exports',
        label: 'Exports'
      }, {
        url: '/reference/api/crm/imports',
        label: 'Imports'
      }, {
        url: '/reference/api/crm/limits-tracking',
        label: 'Limits tracking'
      }, {
        url: '/reference/api/crm/lists',
        label: 'Lists'
      }, {
        label: 'Objects',
        items: [{
          url: '/reference/api/crm/objects/companies',
          label: 'Companies'
        }, {
          url: '/reference/api/crm/objects/contacts',
          label: 'Contacts'
        }, {
          url: '/reference/api/crm/objects/deal-splits',
          label: 'Deal splits'
        }, {
          url: '/reference/api/crm/objects/deals',
          label: 'Deals'
        }, {
          url: '/reference/api/crm/objects/feedback-submissions',
          label: 'Feedback submissions'
        }, {
          url: '/reference/api/crm/objects/goals',
          badge: 'NEW',
          label: 'Goals'
        }, {
          url: '/reference/api/crm/objects/leads',
          label: 'Leads'
        }, {
          url: '/reference/api/crm/objects/line-items',
          label: 'Line items'
        }, {
          url: '/reference/api/crm/objects/object-library',
          label: 'Object library'
        }, {
          url: '/reference/api/crm/objects/objects',
          label: 'Objects'
        }, {
          url: '/reference/api/crm/objects/partner-clients',
          badge: 'BETA',
          label: 'Partner clients'
        }, {
          url: '/reference/api/crm/objects/products',
          label: 'Products'
        }, {
          url: '/reference/api/crm/objects/schemas',
          label: 'Schemas'
        }, {
          url: '/reference/api/crm/objects/tickets',
          label: 'Tickets'
        }],
        dir: 'objects',
        url: '/reference/api/crm/objects'
      }, {
        url: '/reference/api/crm/owners',
        label: 'Owners'
      }, {
        url: '/reference/api/crm/pipelines',
        label: 'Pipelines'
      }, {
        url: '/reference/api/crm/properties',
        label: 'Properties'
      }, {
        url: '/reference/api/crm/property-validations',
        label: 'Property validations'
      }, {
        url: '/reference/api/crm/sensitive-data',
        label: 'Sensitive Data'
      }],
      dir: 'crm',
      url: '/reference/api/crm',
      position: 7
    }, {
      label: 'Marketing',
      items: [{
        url: '/reference/api/marketing/calls-to-action-sdk',
        label: 'Calls-to-action JavaScript API'
      }, {
        url: '/reference/api/marketing/campaigns',
        label: 'Campaigns'
      }, {
        label: 'Emails',
        items: [{
          url: '/reference/api/marketing/emails/marketing-emails',
          badge: 'BETA',
          label: 'Marketing emails'
        }, {
          url: '/reference/api/marketing/emails/single-send-api',
          badge: 'NEW',
          label: 'Single Send API'
        }, {
          url: '/reference/api/marketing/emails/transactional-emails',
          label: 'Transactional emails'
        }],
        dir: 'emails',
        url: '/reference/api/marketing/emails'
      }, {
        url: '/reference/api/marketing/forms',
        label: 'Forms'
      }, {
        url: '/reference/api/marketing/marketing-events',
        label: 'Marketing events'
      }, {
        url: '/reference/api/marketing/subscriptions',
        label: 'Subscriptions'
      }],
      dir: 'marketing',
      url: '/reference/api/marketing',
      position: 8
    }, {
      label: 'Settings',
      items: [{
        url: '/reference/api/settings/account-activity-api',
        label: 'Account activity'
      }, {
        url: '/reference/api/settings/account-information-api',
        label: 'Account information'
      }, {
        url: '/reference/api/settings/business-units-api',
        label: 'Business units'
      }, {
        url: '/reference/api/settings/currencies',
        badge: 'NEW',
        label: 'Currencies'
      }, {
        label: 'Users',
        items: [{
          url: '/reference/api/settings/users/user-details',
          label: 'User details'
        }, {
          url: '/reference/api/settings/users/user-provisioning',
          label: 'User provisioning'
        }],
        dir: 'users',
        url: '/reference/api/settings/users'
      }],
      dir: 'settings',
      url: '/reference/api/settings',
      position: 9
    }, {
      label: 'Other resources',
      items: [{
        url: '/reference/api/other-resources/error-handling',
        label: 'Error handling'
      }],
      dir: 'other-resources',
      url: '/reference/api/other-resources',
      position: 10
    }, {
      label: 'Legal',
      items: [{
        url: 'https://legal.hubspot.com/hubspot-developer-policy',
        position: 1,
        label: 'Developer Policy',
        external: true,
        key: 'external-/reference/api/legal-1'
      }, {
        url: 'https://legal.hubspot.com/developer-terms',
        position: 2,
        label: 'Developer Terms',
        external: true,
        key: 'external-/reference/api/legal-2'
      }, {
        url: 'https://legal.hubspot.com/acceptable-use',
        position: 3,
        label: 'Acceptable Use',
        external: true,
        key: 'external-/reference/api/legal-3'
      }],
      dir: 'legal',
      url: '/reference/api/legal',
      position: 11
    }, {
      url: '/reference/api/deprecated',
      label: 'Deprecated'
    }]
  },
  '/reference/ui-components': {
    items: [{
      position: 1,
      url: '/reference/ui-components/overview',
      label: 'Overview'
    }, {
      label: 'Design',
      items: [{
        position: 2,
        url: '/reference/ui-components/design/figma-design-kit',
        label: 'Figma design kit'
      }, {
        url: '/reference/ui-components/design/manage-ui-extension-layout',
        label: 'Manage layout'
      }, {
        label: 'Patterns',
        items: [{
          url: '/reference/ui-components/design/patterns/buttons',
          label: 'Buttons'
        }, {
          url: '/reference/ui-components/design/patterns/forms',
          label: 'Forms'
        }, {
          url: '/reference/ui-components/design/patterns/tables',
          label: 'Tables'
        }],
        dir: 'patterns',
        url: '/reference/ui-components/design/patterns'
      }],
      dir: 'design',
      url: '/reference/ui-components/design',
      position: 2
    }, {
      label: 'Standard components',
      items: [{
        url: '/reference/ui-components/standard-components/accordion',
        label: 'Accordion'
      }, {
        url: '/reference/ui-components/standard-components/alert',
        label: 'Alert'
      }, {
        url: '/reference/ui-components/standard-components/box',
        label: 'Box'
      }, {
        url: '/reference/ui-components/standard-components/button',
        label: 'Button'
      }, {
        url: '/reference/ui-components/standard-components/button-row',
        label: 'ButtonRow'
      }, {
        label: 'Charts',
        items: [{
          position: 1,
          url: '/reference/ui-components/standard-components/charts/overview',
          label: 'Overview'
        }, {
          url: '/reference/ui-components/standard-components/charts/bar-chart',
          label: 'BarChart'
        }, {
          url: '/reference/ui-components/standard-components/charts/line-chart',
          label: 'LineChart'
        }],
        dir: 'charts',
        url: '/reference/ui-components/standard-components/charts'
      }, {
        url: '/reference/ui-components/standard-components/checkbox',
        label: 'Checkbox'
      }, {
        url: '/reference/ui-components/standard-components/date-input',
        label: 'DateInput'
      }, {
        url: '/reference/ui-components/standard-components/description-list',
        label: 'DescriptionList'
      }, {
        url: '/reference/ui-components/standard-components/divider',
        label: 'Divider'
      }, {
        url: '/reference/ui-components/standard-components/dropdown',
        label: 'Dropdown'
      }, {
        url: '/reference/ui-components/standard-components/empty-state',
        label: 'EmptyState'
      }, {
        url: '/reference/ui-components/standard-components/error-state',
        label: 'ErrorState'
      }, {
        url: '/reference/ui-components/standard-components/flex',
        label: 'Flex'
      }, {
        url: '/reference/ui-components/standard-components/form',
        label: 'Form'
      }, {
        url: '/reference/ui-components/standard-components/heading',
        label: 'Heading'
      }, {
        url: '/reference/ui-components/standard-components/icon',
        label: 'Icon'
      }, {
        url: '/reference/ui-components/standard-components/image',
        label: 'Image'
      }, {
        url: '/reference/ui-components/standard-components/input',
        label: 'Input'
      }, {
        url: '/reference/ui-components/standard-components/link',
        label: 'Link'
      }, {
        url: '/reference/ui-components/standard-components/list',
        label: 'List'
      }, {
        url: '/reference/ui-components/standard-components/loading-button',
        label: 'LoadingButton'
      }, {
        url: '/reference/ui-components/standard-components/loading-spinner',
        label: 'LoadingSpinner'
      }, {
        url: '/reference/ui-components/standard-components/modal',
        label: 'Modal'
      }, {
        url: '/reference/ui-components/standard-components/multi-select',
        label: 'MultiSelect'
      }, {
        url: '/reference/ui-components/standard-components/number-input',
        label: 'NumberInput'
      }, {
        url: '/reference/ui-components/standard-components/panel',
        label: 'Panel'
      }, {
        url: '/reference/ui-components/standard-components/progress-bar',
        label: 'ProgressBar'
      }, {
        url: '/reference/ui-components/standard-components/radio-button',
        label: 'RadioButton'
      }, {
        url: '/reference/ui-components/standard-components/select',
        label: 'Select'
      }, {
        url: '/reference/ui-components/standard-components/statistics',
        label: 'Statistics'
      }, {
        url: '/reference/ui-components/standard-components/status-tag',
        label: 'StatusTag'
      }, {
        url: '/reference/ui-components/standard-components/step-indicator',
        label: 'StepIndicator'
      }, {
        url: '/reference/ui-components/standard-components/stepper-input',
        label: 'StepperInput'
      }, {
        url: '/reference/ui-components/standard-components/table',
        label: 'Table'
      }, {
        url: '/reference/ui-components/standard-components/tag',
        label: 'Tag'
      }, {
        url: '/reference/ui-components/standard-components/text',
        label: 'Text'
      }, {
        url: '/reference/ui-components/standard-components/text-area',
        label: 'TextArea'
      }, {
        url: '/reference/ui-components/standard-components/tile',
        label: 'Tile'
      }, {
        url: '/reference/ui-components/standard-components/toggle',
        label: 'Toggle'
      }, {
        url: '/reference/ui-components/standard-components/toggle-group',
        label: 'ToggleGroup'
      }],
      dir: 'standard-components',
      url: '/reference/ui-components/standard-components',
      position: 3
    }, {
      label: 'CRM action components',
      items: [{
        position: 1,
        url: '/reference/ui-components/crm-action-components/overview',
        label: 'Overview'
      }, {
        url: '/reference/ui-components/crm-action-components/crm-action-button',
        label: 'CrmActionButton'
      }, {
        url: '/reference/ui-components/crm-action-components/crm-action-link',
        label: 'CrmActionLink'
      }, {
        url: '/reference/ui-components/crm-action-components/crm-card-actions',
        label: 'CrmCardActions'
      }],
      dir: 'crm-action-components',
      url: '/reference/ui-components/crm-action-components'
    }, {
      label: 'CRM data components',
      items: [{
        position: 1,
        url: '/reference/ui-components/crm-data-components/overview',
        label: 'Overview'
      }, {
        url: '/reference/ui-components/crm-data-components/crm-association-pivot',
        label: 'CrmAssociationPivot'
      }, {
        url: '/reference/ui-components/crm-data-components/crm-association-property-list',
        label: 'CrmAssociationPropertyList'
      }, {
        url: '/reference/ui-components/crm-data-components/crm-association-stage-tracker',
        label: 'CrmAssociationStageTracker'
      }, {
        url: '/reference/ui-components/crm-data-components/crm-association-table',
        label: 'CrmAssociationTable'
      }, {
        url: '/reference/ui-components/crm-data-components/crm-data-highlight',
        label: 'CrmDataHighlight'
      }, {
        url: '/reference/ui-components/crm-data-components/crm-property-list',
        label: 'CrmPropertyList'
      }, {
        url: '/reference/ui-components/crm-data-components/crm-report',
        label: 'CrmReport'
      }, {
        url: '/reference/ui-components/crm-data-components/crm-stage-tracker',
        label: 'CrmStageTracker'
      }, {
        url: '/reference/ui-components/crm-data-components/crm-statistics',
        label: 'CrmStatistics'
      }],
      dir: 'crm-data-components',
      url: '/reference/ui-components/crm-data-components'
    }]
  },
  '/guides/api': {
    items: [{
      position: 1,
      url: '/guides/api/overview',
      label: 'API developer guides and resources'
    }, {
      label: 'Analytics and events',
      items: [{
        label: 'Custom events',
        items: [{
          position: 1,
          url: '/guides/api/analytics-and-events/custom-events/custom-event-completions',
          label: 'Custom event completions'
        }, {
          url: '/guides/api/analytics-and-events/custom-events/custom-event-definitions',
          label: 'Custom event definitions'
        }],
        dir: 'custom-events',
        url: '/guides/api/analytics-and-events/custom-events',
        position: 1
      }, {
        position: 2,
        url: '/guides/api/analytics-and-events/email-analytics',
        label: 'Email analytics'
      }, {
        position: 3,
        url: '/guides/api/analytics-and-events/event-analytics',
        label: 'Event analytics'
      }, {
        url: '/guides/api/analytics-and-events/reporting',
        label: 'Reporting'
      }],
      dir: 'analytics-and-events',
      url: '/guides/api/analytics-and-events',
      position: 2
    }, {
      label: 'App management',
      items: [{
        url: '/guides/api/app-management/oauth-tokens',
        label: 'OAuth tokens'
      }, {
        url: '/guides/api/app-management/webhooks',
        label: 'Webhooks'
      }],
      dir: 'app-management',
      url: '/guides/api/app-management',
      position: 3
    }, {
      label: 'Automation',
      items: [{
        position: 1,
        url: '/guides/api/automation/create-manage-workflows',
        label: 'Create & manage workflows'
      }, {
        position: 2,
        url: '/guides/api/automation/custom-workflow-actions',
        label: 'Custom workflow actions'
      }, {
        position: 3,
        url: '/guides/api/automation/sequences',
        label: 'Sequences'
      }, {
        url: '/guides/api/automation/workflows-v4',
        label: 'Workflows v4',
        badge: 'BETA'
      }],
      dir: 'automation',
      url: '/guides/api/automation',
      position: 4
    }, {
      label: 'CMS',
      items: [{
        label: 'Blogs',
        items: [{
          url: '/guides/api/cms/blogs/blog-authors',
          label: 'Blog authors'
        }, {
          url: '/guides/api/cms/blogs/blog-posts',
          label: 'Blog posts'
        }, {
          url: '/guides/api/cms/blogs/blog-tags',
          label: 'Blog tags'
        }],
        dir: 'blogs',
        url: '/guides/api/cms/blogs',
        position: 1
      }, {
        position: 2,
        url: '/guides/api/cms/content-audit',
        label: 'Content audit'
      }, {
        position: 3,
        url: '/guides/api/cms/domains',
        label: 'Domains'
      }, {
        position: 4,
        url: '/guides/api/cms/hubdb',
        label: 'HubDB'
      }, {
        position: 5,
        url: '/guides/api/cms/media-bridge',
        label: 'Media bridge'
      }, {
        position: 6,
        url: '/guides/api/cms/pages',
        label: 'Pages'
      }, {
        position: 8,
        url: '/guides/api/cms/site-search',
        label: 'Site search'
      }, {
        url: '/guides/api/cms/source-code',
        label: 'Source code'
      }, {
        url: '/guides/api/cms/url-redirects',
        label: 'URL redirects'
      }],
      dir: 'cms',
      url: '/guides/api/cms',
      position: 5
    }, {
      label: 'Conversations',
      items: [{
        position: 1,
        url: '/guides/api/conversations/create-custom-channels',
        label: 'Create custom channels',
        badge: 'BETA'
      }, {
        position: 2,
        url: '/guides/api/conversations/inbox-and-messages',
        label: 'Inbox & messages'
      }, {
        label: 'Mobile chat SDK',
        items: [{
          url: '/guides/api/conversations/mobile-chat-sdk/android',
          label: 'Android'
        }, {
          url: '/guides/api/conversations/mobile-chat-sdk/ios',
          label: 'iOS'
        }],
        dir: 'mobile-chat-sdk',
        url: '/guides/api/conversations/mobile-chat-sdk'
      }, {
        url: '/guides/api/conversations/visitor-identification',
        label: 'Visitor identification'
      }, {
        label: 'Working with chatbots',
        items: [{
          url: '/guides/api/conversations/working-with-chatbots/code-snippets',
          label: 'Code snippets'
        }, {
          url: '/guides/api/conversations/working-with-chatbots/webhooks',
          label: 'Webhooks'
        }],
        dir: 'working-with-chatbots',
        url: '/guides/api/conversations/working-with-chatbots'
      }],
      dir: 'conversations',
      url: '/guides/api/conversations',
      position: 6
    }, {
      label: 'Library',
      items: [{
        url: '/guides/api/library/files',
        label: 'Files'
      }, {
        url: '/guides/api/library/meetings',
        label: 'Meetings'
      }],
      dir: 'library',
      url: '/guides/api/library',
      position: 8
    }, {
      label: 'CRM',
      items: [{
        position: 1,
        url: '/guides/api/crm/understanding-the-crm',
        label: 'Understanding the CRM'
      }, {
        position: 2,
        url: '/guides/api/crm/using-object-apis',
        label: 'Using object APIs'
      }, {
        label: 'Associations',
        items: [{
          position: 1,
          url: '/guides/api/crm/associations/associations-v3',
          label: 'Associations v3'
        }, {
          url: '/guides/api/crm/associations/associations-v4',
          label: 'Associations v4'
        }],
        dir: 'associations',
        url: '/guides/api/crm/associations',
        position: 3
      }, {
        label: 'Commerce',
        items: [{
          position: 1,
          url: '/guides/api/crm/commerce/carts',
          label: 'Carts'
        }, {
          position: 2,
          url: '/guides/api/crm/commerce/discounts',
          label: 'Discounts'
        }, {
          position: 3,
          url: '/guides/api/crm/commerce/fees',
          label: 'Fees'
        }, {
          position: 4,
          url: '/guides/api/crm/commerce/invoices',
          label: 'Invoices (read)'
        }, {
          position: 5,
          url: '/guides/api/crm/commerce/create-update-delete-invoices',
          label: 'Invoices (write)',
          badge: 'BETA'
        }, {
          position: 6,
          url: '/guides/api/crm/commerce/orders',
          label: 'Orders'
        }, {
          position: 7,
          url: '/guides/api/crm/commerce/payments',
          label: 'Payments'
        }, {
          position: 8,
          url: '/guides/api/crm/commerce/quotes',
          label: 'Quotes'
        }, {
          position: 9,
          url: '/guides/api/crm/commerce/subscriptions',
          label: 'Subscriptions (read)'
        }, {
          position: 10,
          url: '/guides/api/crm/commerce/create-and-manage-subscriptions',
          label: 'Subscription (write)',
          badge: 'BETA'
        }, {
          position: 11,
          url: '/guides/api/crm/commerce/taxes',
          label: 'Taxes'
        }],
        dir: 'commerce',
        url: '/guides/api/crm/commerce',
        position: 4
      }, {
        label: 'Engagements',
        items: [{
          position: 1,
          url: '/guides/api/crm/engagements/engagement-details',
          label: 'Engagement details'
        }, {
          url: '/guides/api/crm/engagements/calls',
          label: 'Calls'
        }, {
          url: '/guides/api/crm/engagements/communications',
          label: 'Communications'
        }, {
          url: '/guides/api/crm/engagements/email',
          label: 'Email'
        }, {
          url: '/guides/api/crm/engagements/meetings',
          label: 'Meetings'
        }, {
          url: '/guides/api/crm/engagements/notes',
          label: 'Notes'
        }, {
          url: '/guides/api/crm/engagements/postal-mail',
          label: 'Postal mail'
        }, {
          url: '/guides/api/crm/engagements/tasks',
          label: 'Tasks'
        }],
        dir: 'engagements',
        url: '/guides/api/crm/engagements',
        position: 5
      }, {
        label: 'Extensions',
        items: [{
          position: 1,
          url: '/guides/api/crm/extensions/calling-sdk',
          label: 'Calling SDK'
        }, {
          position: 2,
          url: '/guides/api/crm/extensions/crm-cards',
          label: 'CRM cards'
        }, {
          position: 3,
          url: '/guides/api/crm/extensions/timeline',
          label: 'Timeline events'
        }, {
          url: '/guides/api/crm/extensions/third-party-calling',
          label: 'Third-party calling',
          badge: 'BETA'
        }, {
          url: '/guides/api/crm/extensions/video-conferencing',
          label: 'Video conferencing extension'
        }],
        dir: 'extensions',
        url: '/guides/api/crm/extensions',
        position: 6
      }, {
        position: 7,
        url: '/guides/api/crm/exports',
        label: 'Export CRM data'
      }, {
        position: 8,
        url: '/guides/api/crm/imports',
        label: 'Import CRM data'
      }, {
        label: 'Lists',
        items: [{
          position: 1,
          url: '/guides/api/crm/lists/overview',
          label: 'Overview'
        }, {
          url: '/guides/api/crm/lists/lists-filters',
          label: 'List filters'
        }],
        dir: 'lists',
        url: '/guides/api/crm/lists',
        position: 9
      }, {
        label: 'Objects',
        items: [{
          url: '/guides/api/crm/objects/companies',
          label: 'Companies'
        }, {
          url: '/guides/api/crm/objects/contacts',
          label: 'Contacts'
        }, {
          url: '/guides/api/crm/objects/custom-objects',
          label: 'Custom objects'
        }, {
          url: '/guides/api/crm/objects/deal-splits',
          label: 'Deal splits'
        }, {
          url: '/guides/api/crm/objects/deals',
          label: 'Deals'
        }, {
          url: '/guides/api/crm/objects/feedback-submissions',
          label: 'Feedback submissions'
        }, {
          url: '/guides/api/crm/objects/goals',
          label: 'Goals',
          badge: 'NEW'
        }, {
          url: '/guides/api/crm/objects/leads',
          label: 'Leads'
        }, {
          url: '/guides/api/crm/objects/line-items',
          label: 'Line items'
        }, {
          url: '/guides/api/crm/objects/products',
          label: 'Products'
        }, {
          url: '/guides/api/crm/objects/tickets',
          label: 'Tickets'
        }],
        dir: 'objects',
        url: '/guides/api/crm/objects',
        position: 10
      }, {
        position: 11,
        url: '/guides/api/crm/embed',
        label: 'CRM embed'
      }, {
        url: '/guides/api/crm/limits-tracking',
        label: 'Limits tracking'
      }, {
        url: '/guides/api/crm/object-library',
        label: 'Object library'
      }, {
        url: '/guides/api/crm/owners',
        label: 'Owners'
      }, {
        url: '/guides/api/crm/pipelines',
        label: 'Pipelines'
      }, {
        url: '/guides/api/crm/properties',
        label: 'Properties'
      }, {
        url: '/guides/api/crm/property-validations',
        label: 'Property validations'
      }, {
        url: '/guides/api/crm/search',
        label: 'Search'
      }],
      dir: 'crm',
      url: '/guides/api/crm',
      position: 9
    }, {
      label: 'Marketing',
      items: [{
        position: 1,
        url: '/guides/api/marketing/campaigns',
        label: 'Campaigns'
      }, {
        label: 'Emails',
        items: [{
          url: '/guides/api/marketing/emails/marketing-emails',
          label: 'Marketing emails'
        }, {
          url: '/guides/api/marketing/emails/single-send-api',
          label: 'Single send API'
        }, {
          url: '/guides/api/marketing/emails/transactional-emails',
          label: 'Transactional emails'
        }],
        dir: 'emails',
        url: '/guides/api/marketing/emails',
        position: 2
      }, {
        label: 'Forms',
        items: [{
          position: 3,
          url: '/guides/api/marketing/forms/forms',
          label: 'Forms'
        }, {
          url: '/guides/api/marketing/forms/global-form-events',
          label: 'Global form events'
        }],
        dir: 'forms',
        url: '/guides/api/marketing/forms',
        position: 2
      }, {
        position: 4,
        url: '/guides/api/marketing/marketing-events',
        label: 'Marketing events'
      }, {
        position: 5,
        url: '/guides/api/marketing/subscriptions',
        label: 'Subscriptions v4'
      }, {
        position: 6,
        url: '/guides/api/marketing/subscriptions-preferences',
        label: 'Subscriptions v3'
      }],
      dir: 'marketing',
      url: '/guides/api/marketing',
      position: 9
    }, {
      label: 'Settings',
      items: [{
        url: '/guides/api/settings/account-activity-api',
        label: 'Account activity'
      }, {
        url: '/guides/api/settings/account-information-api',
        label: 'Account information'
      }, {
        url: '/guides/api/settings/business-units-api',
        label: 'Business units'
      }, {
        url: '/guides/api/settings/currencies',
        label: 'Currencies',
        badge: 'NEW'
      }, {
        label: 'Users',
        items: [{
          url: '/guides/api/settings/users/public-permissions',
          label: 'Public permissions',
          badge: 'NEW'
        }, {
          url: '/guides/api/settings/users/user-details',
          label: 'User details'
        }, {
          url: '/guides/api/settings/users/user-provisioning',
          label: 'User provisioning'
        }],
        dir: 'users',
        url: '/guides/api/settings/users'
      }],
      dir: 'settings',
      url: '/guides/api/settings',
      position: 10
    }, {
      label: 'Legal',
      items: [{
        url: 'https://legal.hubspot.com/hubspot-developer-policy',
        position: 1,
        label: 'Developer Policy',
        external: true,
        key: 'external-/guides/api/legal-1'
      }, {
        url: 'https://legal.hubspot.com/developer-terms',
        position: 2,
        label: 'Developer Terms',
        external: true,
        key: 'external-/guides/api/legal-2'
      }, {
        url: 'https://legal.hubspot.com/acceptable-use',
        position: 3,
        label: 'Acceptable Use',
        external: true,
        key: 'external-/guides/api/legal-3'
      }],
      dir: 'legal',
      url: '/guides/api/legal'
    }]
  },
  '/guides/cms': {
    items: [{
      position: 1,
      url: '/guides/cms/overview',
      label: 'Overview'
    }, {
      label: 'Tools',
      items: [{
        label: 'HubSpot CLI',
        items: [{
          position: 1,
          url: '/guides/cms/tools/hubspot-cli/cli-v7',
          label: 'CLI commands (v7)',
          badge: 'NEW'
        }, {
          position: 2,
          url: '/guides/cms/tools/hubspot-cli/local-development-cli',
          label: 'CLI commands (v6)'
        }],
        dir: 'hubspot-cli',
        url: '/guides/cms/tools/hubspot-cli',
        position: 1
      }, {
        url: '/guides/cms/tools/design-manager',
        label: 'Design manager'
      }, {
        url: '/guides/cms/tools/personal-access-key',
        label: 'Personal access key'
      }],
      dir: 'tools',
      url: '/guides/cms/tools',
      position: 2
    }, {
      position: 3,
      url: '/guides/cms/quickstart',
      label: 'Quickstart'
    }, {
      label: 'Setup',
      items: [{
        url: '/guides/cms/setup/github-integration',
        label: 'GitHub integration'
      }, {
        url: '/guides/cms/setup/getting-started-with-local-development',
        label: 'Local development with HubSpot CLI'
      }, {
        url: '/guides/cms/setup/creating-an-efficient-development-workflow',
        label: 'Optimize your workflow'
      }, {
        url: '/guides/cms/setup/js-frameworks',
        label: 'Use JavaScript frameworks with HubSpot'
      }, {
        url: '/guides/cms/setup/install-and-use-hubspot-code-extension',
        label: 'VS Code extension'
      }],
      dir: 'setup',
      url: '/guides/cms/setup',
      position: 4
    }, {
      label: 'Storage',
      items: [{
        url: '/guides/cms/storage/file-manager',
        label: 'File manager'
      }, {
        label: 'HubDB',
        items: [{
          position: 1,
          url: '/guides/cms/storage/hubdb/overview',
          label: 'Overview'
        }, {
          url: '/guides/cms/storage/hubdb/location-based-pages',
          label: 'Location-based pages'
        }],
        dir: 'hubdb',
        url: '/guides/cms/storage/hubdb'
      }],
      dir: 'storage',
      url: '/guides/cms/storage',
      position: 5
    }, {
      label: 'Content and Structure',
      items: [{
        position: 1,
        url: '/guides/cms/content/overview',
        label: 'Overview'
      }, {
        label: 'CDN, security, and performance',
        items: [{
          url: '/guides/cms/content/performance/lazy-loading',
          label: 'Lazy loading'
        }, {
          url: '/guides/cms/content/performance/speed',
          label: 'Optimize site performance'
        }, {
          url: '/guides/cms/content/performance/overview',
          label: 'Overview'
        }, {
          url: '/guides/cms/content/performance/prerendering',
          label: 'Prerendering'
        }, {
          url: '/guides/cms/content/performance/reverse-proxy-support',
          label: 'Reverse proxy support'
        }],
        dir: 'performance',
        url: '/guides/cms/content/performance',
        position: 2
      }, {
        label: 'Themes',
        items: [{
          position: 1,
          url: '/guides/cms/content/themes/overview',
          label: 'Overview'
        }, {
          position: 2,
          url: '/guides/cms/content/themes/getting-started',
          label: 'Getting started'
        }, {
          position: 3,
          url: '/guides/cms/content/themes/hubspot-cms-boilerplate',
          label: 'CMS boilerplate'
        }, {
          url: '/guides/cms/content/themes/build-and-deploy-with-projects',
          label: 'Build and deploy with Projects'
        }, {
          url: '/guides/cms/content/themes/child-themes',
          label: 'Child themes'
        }, {
          url: '/guides/cms/content/themes/default-themes',
          label: 'Default themes'
        }, {
          url: '/guides/cms/content/themes/quotes-themes',
          label: 'Quotes themes'
        }, {
          url: '/guides/cms/content/themes/responsive-breakpoints',
          label: 'Responsive breakpoints'
        }],
        dir: 'themes',
        url: '/guides/cms/content/themes',
        position: 3
      }, {
        label: 'Template Types',
        items: [{
          position: 1,
          url: '/guides/cms/content/templates/overview',
          label: 'Overview'
        }, {
          position: 2,
          url: '/guides/cms/content/templates/default-email-modules',
          label: 'Default email modules'
        }, {
          label: 'Template types',
          items: [{
            url: '/guides/cms/content/templates/types/blog',
            label: 'Blog templates'
          }, {
            url: '/guides/cms/content/templates/types/drag-and-drop-templates',
            label: 'Drag and drop templates'
          }, {
            url: '/guides/cms/content/templates/types/email-template-markup',
            label: 'Email templates'
          }, {
            url: '/guides/cms/content/templates/types/html-hubl-templates',
            label: 'HTML + HubL templates'
          }, {
            url: '/guides/cms/content/templates/types/quotes',
            label: 'Quotes'
          }],
          dir: 'types',
          url: '/guides/cms/content/templates/types',
          position: 3
        }, {
          label: 'Drag and drop',
          items: [{
            position: 1,
            url: '/guides/cms/content/templates/drag-and-drop/overview',
            label: 'Overview'
          }, {
            position: 2,
            url: '/guides/cms/content/templates/drag-and-drop/custom-email-template',
            label: 'Drag and drop area in a custom email template'
          }, {
            url: '/guides/cms/content/templates/drag-and-drop/tutorial',
            label: 'Drag and drop areas tutorial'
          }, {
            url: '/guides/cms/content/templates/drag-and-drop/sections',
            label: 'Sections'
          }],
          dir: 'drag-and-drop',
          url: '/guides/cms/content/templates/drag-and-drop'
        }],
        dir: 'templates',
        url: '/guides/cms/content/templates',
        position: 4
      }, {
        label: 'Modules',
        items: [{
          position: 1,
          url: '/guides/cms/content/modules/overview',
          label: 'Overview'
        }, {
          position: 2,
          url: '/guides/cms/content/modules/quickstart',
          label: 'Quickstart'
        }, {
          url: '/guides/cms/content/modules/build-modules-and-partials-with-react',
          label: 'Build modules and partials with JavaScript'
        }, {
          url: '/guides/cms/content/modules/hide-modules-and-sections',
          label: 'Hide modules and sections from the editor'
        }],
        dir: 'modules',
        url: '/guides/cms/content/modules',
        position: 5
      }, {
        label: 'Fields',
        items: [{
          position: 1,
          url: '/guides/cms/content/fields/overview',
          label: 'Overview'
        }, {
          position: 2,
          url: '/guides/cms/content/fields/best-practices',
          label: 'Module and theme fields best practices'
        }, {
          url: '/guides/cms/content/fields/alias-mapping',
          label: 'Alias mapping'
        }, {
          url: '/guides/cms/content/fields/brand-and-settings-inheritance',
          label: 'Brand and settings inheritance'
        }, {
          url: '/guides/cms/content/fields/custom-fonts',
          label: 'Custom fonts'
        }, {
          url: '/guides/cms/content/fields/oembed',
          label: 'oEmbed'
        }, {
          url: '/guides/cms/content/fields/write-fields-using-javascript',
          label: 'Write fields using JavaScript'
        }],
        dir: 'fields',
        url: '/guides/cms/content/fields',
        position: 6
      }, {
        label: 'Data-driven content',
        items: [{
          position: 1,
          url: '/guides/cms/content/data-driven-content/crm-objects',
          label: 'CRM objects'
        }, {
          label: 'Dynamic pages',
          items: [{
            position: 1,
            url: '/guides/cms/content/data-driven-content/dynamic-pages/overview',
            label: 'Overview'
          }, {
            url: '/guides/cms/content/data-driven-content/dynamic-pages/multilevel',
            label: 'Build multi-level dynamic pages with HubDB'
          }, {
            url: '/guides/cms/content/data-driven-content/dynamic-pages/crm-objects',
            label: 'CRM objects'
          }, {
            url: '/guides/cms/content/data-driven-content/dynamic-pages/video',
            label: 'How to add videos to dynamic pages in HubDB'
          }, {
            url: '/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page',
            label: 'How to build a dynamic team member page with HubDB'
          }, {
            url: '/guides/cms/content/data-driven-content/dynamic-pages/hubdb',
            label: 'HubDB'
          }],
          dir: 'dynamic-pages',
          url: '/guides/cms/content/data-driven-content/dynamic-pages',
          position: 2
        }, {
          label: 'GraphQL',
          items: [{
            url: '/guides/cms/content/data-driven-content/graphql/build-a-recruiting-agency-using-graphql',
            label: 'Build a recruiting agency using GraphQL',
            badge: 'BETA'
          }, {
            url: '/guides/cms/content/data-driven-content/graphql/query-hubspot-data-using-graphql',
            label: 'Query HubSpot data using GraphQL'
          }, {
            url: '/guides/cms/content/data-driven-content/graphql/use-graphql-data-in-your-website-pages',
            label: 'Use GraphQL data in pages'
          }],
          dir: 'graphql',
          url: '/guides/cms/content/data-driven-content/graphql',
          position: 3
        }, {
          label: 'Serverless functions',
          items: [{
            position: 1,
            url: '/guides/cms/content/data-driven-content/serverless-functions/overview',
            label: 'Overview'
          }, {
            url: '/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions',
            label: 'Get started with serverless functions'
          }],
          dir: 'serverless-functions',
          url: '/guides/cms/content/data-driven-content/serverless-functions',
          position: 4
        }, {
          url: '/guides/cms/content/data-driven-content/emails-with-programmable-content',
          label: 'Emails with programmable content'
        }],
        dir: 'data-driven-content',
        url: '/guides/cms/content/data-driven-content',
        position: 7
      }, {
        url: '/guides/cms/content/accessibility',
        label: 'Accessibility'
      }, {
        url: '/guides/cms/content/content-search',
        label: 'Content search'
      }, {
        url: '/guides/cms/content/content-staging',
        label: 'Content staging'
      }, {
        url: '/guides/cms/content/forms',
        label: 'Forms'
      }, {
        url: '/guides/cms/content/global-content',
        label: 'Global content (and partials)'
      }, {
        label: 'Memberships',
        items: [{
          position: 1,
          url: '/guides/cms/content/memberships/overview',
          label: 'Overview'
        }, {
          url: '/guides/cms/content/memberships/google-sign-in',
          label: 'Google Sign-In for Memberships'
        }, {
          url: '/guides/cms/content/memberships/microsoft-sign-in',
          label: 'Microsoft Sign-In for Memberships'
        }, {
          url: '/guides/cms/content/memberships/social',
          label: 'Social login for membership pages'
        }, {
          url: '/guides/cms/content/memberships/sso',
          label: 'SSO for memberships'
        }],
        dir: 'memberships',
        url: '/guides/cms/content/memberships'
      }, {
        url: '/guides/cms/content/menus-and-navigation',
        label: 'Menus and navigation'
      }, {
        url: '/guides/cms/content/multi-language-content',
        label: 'Multi-language'
      }, {
        url: '/guides/cms/content/provide-a-good-editor-experience',
        label: 'Provide a good editor experience'
      }, {
        url: '/guides/cms/content/website-settings',
        label: 'Website settings'
      }],
      dir: 'content',
      url: '/guides/cms/content'
    }, {
      label: 'Debugging',
      items: [{
        url: '/guides/cms/debugging/code-alerts',
        label: 'Code alerts'
      }, {
        url: '/guides/cms/debugging/troubleshooting',
        label: 'Troubleshooting'
      }],
      dir: 'debugging',
      url: '/guides/cms/debugging'
    }, {
      label: 'Improve older sites',
      items: [{
        url: '/guides/cms/improve-older-sites/add-theme-features-to-existing-sites',
        label: 'Add theme features to existing sites'
      }, {
        url: '/guides/cms/improve-older-sites/convert-wordpress-theme-to-hubspot',
        label: 'Convert a WordPress theme to a HubSpot CMS theme'
      }, {
        url: '/guides/cms/improve-older-sites/upgrade-jquery',
        label: 'Upgrade to the latest jQuery'
      }],
      dir: 'improve-older-sites',
      url: '/guides/cms/improve-older-sites'
    }, {
      label: 'Marketplace',
      items: [{
        url: '/guides/cms/marketplace/general-requirements',
        label: 'General listing requirements'
      }, {
        url: '/guides/cms/marketplace/listing-templates',
        label: 'List and update templates in the HubSpot Template Marketplace'
      }, {
        url: '/guides/cms/marketplace/module-requirements',
        label: 'Module requirements'
      }, {
        url: '/guides/cms/marketplace/template-guidelines',
        label: 'Template marketplace guidelines'
      }, {
        url: '/guides/cms/marketplace/template-policies',
        label: 'Template marketplace policies'
      }, {
        url: '/guides/cms/marketplace/theme-requirements',
        label: 'Theme requirements'
      }],
      dir: 'marketplace',
      url: '/guides/cms/marketplace'
    }]
  },
  '/guides/crm': {
    items: [{
      position: 1,
      url: '/guides/crm/overview',
      label: 'Overview'
    }, {
      label: 'Developer projects',
      items: [{
        url: '/guides/crm/developer-projects/create-a-project',
        label: 'Create a project'
      }, {
        url: '/guides/crm/developer-projects/link-a-github-repository-to-a-project',
        label: 'Github integration'
      }, {
        url: '/guides/crm/developer-projects/project-cli-commands',
        label: 'Projects CLI commands'
      }, {
        url: '/guides/crm/developer-projects/platform-versioning',
        label: 'Versioning for Projects'
      }],
      dir: 'developer-projects',
      url: '/guides/crm/developer-projects'
    }, {
      label: 'Private apps',
      items: [{
        position: 1,
        url: '/guides/crm/private-apps/quickstart',
        label: 'Quickstart'
      }, {
        position: 2,
        url: '/guides/crm/private-apps/creating-private-apps',
        label: 'Creating private apps'
      }, {
        position: 3,
        url: '/guides/crm/private-apps/serverless-functions',
        label: 'Serverless functions'
      }, {
        position: 4,
        url: '/guides/crm/private-apps/webhooks',
        label: 'Webhooks'
      }],
      dir: 'private-apps',
      url: '/guides/crm/private-apps'
    }, {
      label: 'Public Apps',
      items: [{
        position: 1,
        url: '/guides/crm/public-apps/overview',
        label: 'Overview'
      }, {
        position: 2,
        url: '/guides/crm/public-apps/quickstart',
        label: 'Quickstart'
      }, {
        position: 3,
        url: '/guides/crm/public-apps/creating-public-apps',
        label: 'Creating public apps'
      }, {
        position: 4,
        url: '/guides/crm/public-apps/migrate-a-public-app-to-projects',
        label: 'Migrate existing public apps'
      }, {
        position: 5,
        url: '/guides/crm/public-apps/fetching-data',
        label: 'Fetching data'
      }, {
        position: 6,
        url: '/guides/crm/public-apps/webhooks',
        label: 'Webhooks'
      }],
      dir: 'public-apps',
      url: '/guides/crm/public-apps'
    }, {
      url: '/guides/crm/setup',
      label: 'Setup'
    }, {
      label: 'UI Extensions',
      items: [{
        position: 1,
        url: '/guides/crm/ui-extensions/overview',
        label: 'Overview'
      }, {
        url: '/guides/crm/ui-extensions/create',
        label: 'Create UI Extensions with React'
      }, {
        url: '/guides/crm/ui-extensions/local-development',
        label: 'Local development'
      }, {
        label: 'Sample extensions',
        items: [{
          url: '/guides/crm/ui-extensions/sample-extensions/deals-summary-tutorial',
          label: 'Deals summary tutorial'
        }, {
          url: '/guides/crm/ui-extensions/sample-extensions/overview',
          label: 'Overview'
        }],
        dir: 'sample-extensions',
        url: '/guides/crm/ui-extensions/sample-extensions'
      }, {
        url: '/guides/crm/ui-extensions/sdk',
        label: 'SDK'
      }],
      dir: 'ui-extensions',
      url: '/guides/crm/ui-extensions'
    }]
  },
  '/guides/apps': {
    items: [{
      position: 1,
      url: '/guides/apps/overview',
      label: 'Overview'
    }, {
      label: 'Authentication',
      items: [{
        position: 1,
        url: '/guides/apps/authentication/intro-to-auth',
        label: 'Auth methods on HubSpot'
      }, {
        position: 2,
        url: '/guides/apps/authentication/validating-requests',
        label: 'Request validation'
      }, {
        position: 3,
        url: '/guides/apps/authentication/working-with-oauth',
        label: 'Working with OAuth'
      }, {
        position: 4,
        url: '/guides/apps/authentication/oauth-quickstart-guide',
        label: 'OAuth 2 quickstart'
      }, {
        url: '/guides/apps/authentication/scopes',
        label: 'Scopes'
      }],
      dir: 'authentication',
      url: '/guides/apps/authentication',
      position: 2
    }, {
      label: 'Private apps',
      items: [{
        position: 1,
        url: '/guides/apps/private-apps/overview',
        label: 'Overview'
      }, {
        url: '/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps',
        label: 'Create webhook subscriptions in a private app'
      }, {
        url: '/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app',
        label: 'Migrate to a private app from an API key'
      }],
      dir: 'private-apps',
      url: '/guides/apps/private-apps',
      position: 3
    }, {
      label: 'Public apps',
      items: [{
        position: 1,
        url: '/guides/apps/public-apps/overview',
        label: 'Overview'
      }, {
        url: '/guides/apps/public-apps/create-an-app-settings-page',
        label: 'Create a settings page for a public app'
      }, {
        url: '/guides/apps/public-apps/create-generic-webhook-subscriptions',
        label: 'Create generic webhook subscriptions',
        badge: 'BETA'
      }, {
        url: '/guides/apps/public-apps/import-users-into-any-hubspot-account',
        label: 'Import users into any HubSpot account from an external app'
      }, {
        url: '/guides/apps/public-apps/share-hubspot-reports-with-your-app',
        label: 'Use your installed app to share HubSpot reports to channels and users'
      }],
      dir: 'public-apps',
      url: '/guides/apps/public-apps',
      position: 4
    }, {
      label: 'Extensions',
      items: [{
        position: 1,
        url: '/guides/apps/extensions/overview',
        label: 'Overview'
      }, {
        label: 'Calling extensions',
        items: [{
          url: '/guides/apps/extensions/calling-extensions/receive-incoming-calls',
          label: 'Receive incoming calls'
        }, {
          url: '/guides/apps/extensions/calling-extensions/recordings-and-transcriptions',
          label: 'Recordings & transcripts'
        }],
        dir: 'calling-extensions',
        url: '/guides/apps/extensions/calling-extensions'
      }],
      dir: 'extensions',
      url: '/guides/apps/extensions',
      position: 5
    }, {
      label: 'API Usage',
      items: [{
        url: '/guides/apps/api-usage/breaking-change-definition',
        label: 'Breaking change guidelines'
      }, {
        url: '/guides/apps/api-usage/usage-details',
        label: 'Usage guidelines & rate limits'
      }],
      dir: 'api-usage',
      url: '/guides/apps/api-usage'
    }, {
      label: 'Marketplace',
      items: [{
        url: '/guides/apps/marketplace/certification-requirements',
        label: 'App certification requirements'
      }, {
        url: '/guides/apps/marketplace/app-marketplace-listing-requirements',
        label: 'App listing requirements'
      }, {
        url: '/guides/apps/marketplace/applying-for-app-certification',
        label: 'Apply for app certification'
      }, {
        url: '/guides/apps/marketplace/listing-your-app',
        label: 'Listing your app'
      }, {
        url: '/guides/apps/marketplace/measuring-app-performance',
        label: 'Measuring app performance'
      }, {
        url: '/guides/apps/marketplace/testing-credentials',
        label: 'Provide testing credentials for your app'
      }, {
        url: '/guides/apps/marketplace/understand-app-categories',
        label: 'Understand app categories'
      }],
      dir: 'marketplace',
      url: '/guides/apps/marketplace'
    }]
  }
};
export const versionInfo = {
  '/analytics-and-events/custom-events/custom-event-completions': {
    legacyVersions: [],
    spec: {
      group: 'Events',
      name: 'Send Event Completions'
    }
  },
  '/analytics-and-events/custom-events/custom-event-definitions': {
    legacyVersions: [],
    spec: {
      group: 'Events',
      name: 'Manage Event Definitions'
    }
  },
  '/analytics-and-events/email-analytics': {
    legacyVersions: [{
      url: '/reference/api/analytics-and-events/email-analytics/v1',
      versionName: 'v1',
      intro: 'Use the email analytics API to fetch events generated by marketing emails, such as sends, bounces, and clicks.',
      isDefaultSelected: true,
      useCase: 'To optimize email campaigns, you want to gather data to power a machine learning model that determines the best time to send emails to different contact segments.',
      relatedDocuments: {
        href: '/guides/api/analytics-and-events/email-analytics',
        title: 'Email events API overview'
      }
    }]
  },
  '/analytics-and-events/event-analytics': {
    legacyVersions: [],
    spec: {
      group: 'Events',
      name: 'Events',
      postmanCollectionId: '95a3ba30b9140023cf3b'
    }
  },
  '/analytics-and-events/reporting': {
    legacyVersions: [{
      url: '/reference/api/analytics-and-events/reporting/v2',
      versionName: 'v2',
      intro: 'Use the reporting API to export analytics and reporting data from HubSpot. Primarily used for connecting HubSpot metrics to metrics stored in other business intelligence tools.',
      isDefaultSelected: true,
      useCase: "You're hosting your blog on HubSpot while storing CRM data externally, and want to see traffic and leads tracked in HubSpot alongside sales activity in your CRM.",
      relatedDocuments: {
        href: '/guides/api/analytics-and-events/reporting',
        title: 'Analytics API overview'
      }
    }]
  },
  '/app-management/feature-flags': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Public App Feature Flags V3',
      postmanCollectionId: '26126890-b4ebfe97-5852-4a5d-a9be-d171474df3b1'
    }
  },
  '/app-management/oauth': {
    legacyVersions: [{
      url: '/reference/api/app-management/oauth/v1',
      versionName: 'v1',
      isDefaultSelected: true,
      relatedDocuments: {
        href: '/guides/api/oauth/tokens',
        title: 'Managing tokens'
      }
    }]
  },
  '/app-management/webhooks': {
    legacyVersions: [],
    spec: {
      group: 'Webhooks',
      name: 'Webhooks',
      postmanCollectionId: '26126890-8f5582df-b553-403f-b001-6fe4cbafe4e4'
    }
  },
  '/automation/create-manage-workflows': {
    legacyVersions: [{
      url: '/reference/api/automation/create-manage-workflows/v3',
      versionName: 'v3',
      intro: 'Use the workflows API to create and manage workflows, enroll and remove contacts from workflows, and get workflow performance data.',
      useCase: 'Create a workflow to automate email sending for an upcoming campaign, then enroll contacts and monitor performance data over time.',
      relatedDocuments: {
        href: '/guides/api/automation/create-manage-workflows',
        title: 'Workflows API overview'
      }
    }, {
      url: '/reference/api/automation/create-manage-workflows/v2',
      versionName: 'v2',
      intro: 'Use the workflows API to create and manage workflows, enroll and remove contacts from workflows, and get workflow performance data.',
      useCase: 'Create a workflow to automate email sending for an upcoming campaign, then enroll contacts and monitor performance data over time.',
      relatedDocuments: {
        href: '/guides/api/automation/create-manage-workflows',
        title: 'Workflows API overview'
      }
    }],
    spec: {
      group: 'Automation',
      name: 'Automation V4'
    }
  },
  '/automation/custom-workflow-actions': {
    legacyVersions: [],
    spec: {
      group: 'Automation',
      name: 'Actions V4'
    }
  },
  '/automation/sequences': {
    legacyVersions: [],
    spec: {
      group: 'Automation',
      name: 'Sequences'
    }
  },
  '/cms/blogs/blog-authors': {
    legacyVersions: [{
      url: '/reference/api/cms/blogs/blog-authors/v3-legacy',
      versionName: 'v3-legacy',
      intro: 'Use the blog authors API to manage information about blog post authors.',
      useCase: 'After hiring a new blog writer, you need to create a new blog author profile for them so that readers can learn more about the author on each of their posts'
    }],
    spec: {
      group: 'CMS',
      name: 'Authors',
      postmanCollectionId: '26126890-451fedfa-4e89-4940-8aaa-223cb248ee10'
    }
  },
  '/cms/blogs/blog-comments': {
    legacyVersions: [{
      url: '/reference/api/cms/blogs/blog-comments/v3',
      versionName: 'v3',
      intro: 'Use the blog comments API to create and manage blog comments, including restoring deleted comments.',
      isDefaultSelected: true,
      useCase: "You want to build a system for automating the moderation of comments left on your company's blog."
    }]
  },
  '/cms/blogs/blog-details': {
    legacyVersions: [{
      url: '/reference/api/cms/blogs/blog-details/v2',
      versionName: 'v2',
      intro: 'Use the blogs API to retrieve information about the blogs in a HubSpot account.',
      isDefaultSelected: true,
      useCase: 'Review revision history for one of the blogs in your HubSpot account to understand when certain changes were made.'
    }]
  },
  '/cms/blogs/blog-posts': {
    legacyVersions: [{
      url: '/reference/api/cms/blogs/blog-posts/v2',
      versionName: 'v2',
      intro: 'Use the blog posts API to create, manage, and publish blog posts on your website.',
      useCase: 'Retrieve all blog posts created this year as a part of a content audit.'
    }],
    spec: {
      group: 'CMS',
      name: 'Posts',
      postmanCollectionId: '26126890-0bedaf71-dce8-4614-82f3-76e95ac2ef15'
    }
  },
  '/cms/blogs/blog-tags': {
    legacyVersions: [],
    spec: {
      group: 'CMS',
      name: 'Tags',
      postmanCollectionId: '85a10386746564133db1'
    }
  },
  '/cms/blogs/blog-topics': {
    legacyVersions: [{
      url: '/reference/api/cms/blogs/blog-topics/v3',
      versionName: 'v3',
      intro: 'Use the blog topics API to create and manage blog topics in a HubSpot account.',
      isDefaultSelected: true,
      useCase: 'Retrieve all blog tags as a part of a content audit, then update and merge specific tags to support an upcoming company rebrand.'
    }]
  },
  '/cms/content-audit': {
    legacyVersions: [],
    spec: {
      group: 'CMS',
      name: 'Cms Content Audit',
      postmanCollectionId: '26126890-30d22393-5cdf-4042-9b43-ca5e7bf0dab5'
    }
  },
  '/cms/domains': {
    legacyVersions: [{
      url: '/reference/api/cms/domains/v1',
      versionName: 'v1',
      intro: 'Use the domain management API to return information about the domains connected to a HubSpot account. You can return data for all currently connected domains, or for a single domain by ID.',
      useCase: 'Retrieve all currently connected domains to see which sites are live in a given account.'
    }],
    spec: {
      group: 'CMS',
      name: 'Domains',
      postmanCollectionId: '85a10386746564133db1'
    }
  },
  '/cms/hubdb': {
    legacyVersions: [],
    spec: {
      group: 'CMS',
      name: 'Hubdb'
    }
  },
  '/cms/layouts': {
    legacyVersions: [{
      url: '/reference/api/cms/layouts/v2',
      versionName: 'v2',
      intro: 'Use the layouts API to retrieve data about global groups for CMS templates in the developer file system. These layouts can be found in the design manager.',
      isDefaultSelected: true,
      useCase: 'Retrieve version history for a global group to understand when certain changes were made.'
    }]
  },
  '/cms/media-bridge': {
    legacyVersions: [],
    spec: {
      group: 'CMS',
      name: 'Media Bridge'
    }
  },
  '/cms/modules': {
    legacyVersions: [{
      url: '/reference/api/cms/modules/v4',
      versionName: 'v4',
      isDefaultSelected: true
    }]
  },
  '/cms/pages': {
    legacyVersions: [{
      url: '/reference/api/cms/pages/v2',
      versionName: 'v2',
      intro: 'Use the pages API to create and manage website pages.',
      useCase: 'After noticing that a website page was updated incorrectly, use the API to view revisions of that page, then restore the page to a previous version.'
    }],
    spec: {
      group: 'CMS',
      name: 'Pages'
    }
  },
  '/cms/site-search': {
    legacyVersions: [{
      url: '/reference/api/cms/site-search/v2',
      versionName: 'v2',
      intro: 'Use the site search API to return HubSpot-hosted content based on search terms. Content can be searched across multiple domains. You can also retrieve all indexed data for a specific website page, blog post, or knowledge base article using its ID.',
      useCase: 'Build a site search bar to enable users to search through your website content.'
    }],
    spec: {
      group: 'CMS',
      name: 'Site Search',
      postmanCollectionId: '5e5c4c70292db4e72738'
    }
  },
  '/cms/source-code': {
    legacyVersions: [],
    spec: {
      group: 'CMS',
      name: 'Source Code'
    }
  },
  '/cms/templates': {
    legacyVersions: [{
      url: '/reference/api/cms/templates/v2',
      versionName: 'v2',
      intro: 'Use the templates API to create and manage CMS templates.',
      isDefaultSelected: true,
      useCase: 'Retrieve version history for a website page template to understand when a certain change was made.'
    }]
  },
  '/cms/url-redirects': {
    legacyVersions: [{
      url: '/reference/api/cms/url-redirects/v3-legacy',
      versionName: 'v3-legacy',
      intro: 'Use the URL redirects API to redirect traffic from a HubSpot-hosted page or blog post to any URL. You can redirect pages to specific URLs or use flexible pattern redirects to redirect multiple pages using variables. Learn more about managing redirects in HubSpot.',
      useCase: 'Redirect one page to another, or redirect an entire blog with one URL using flexible pattern variables.'
    }],
    spec: {
      group: 'CMS',
      name: 'Url Redirects',
      postmanCollectionId: '26126890-8f5582df-b553-403f-b001-6fe4cbafe4e4'
    }
  },
  '/conversations/create-custom-channels': {
    legacyVersions: [],
    spec: {
      group: 'Conversations',
      name: 'Custom Channels'
    }
  },
  '/conversations/inbox-and-messages': {
    legacyVersions: [],
    spec: {
      group: 'Conversations',
      name: 'Conversations Inbox & Messages',
      postmanCollectionId: '26126890-d0340d5d-abd4-4fa6-83ec-4cdcb9bbbcea'
    }
  },
  '/conversations/visitor-identification': {
    legacyVersions: [],
    spec: {
      group: 'Conversations',
      name: 'Visitor Identification'
    }
  },
  '/crm/associations/association-details': {
    legacyVersions: [{
      url: '/reference/api/crm/associations/association-details/v1',
      versionName: 'v1',
      intro: 'Use the associations API to create, retrieve, update, or delete associations between records, or between records and activities.',
      useCase: 'When an existing contact has moved to a new company, update their associated company to keep your CRM up to date.'
    }],
    spec: {
      group: 'CRM',
      name: 'Associations',
      postmanCollectionId: '26126890-9da1d020-5ddb-441c-b79a-4fa54a50dc64'
    }
  },
  '/crm/associations/associations-schema': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Associations Schema',
      postmanCollectionId: '26126890-ebc9dd6f-bbd4-412b-b688-3553b6507c49'
    }
  },
  '/crm/commerce/carts': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Carts'
    }
  },
  '/crm/commerce/discounts': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Discounts'
    }
  },
  '/crm/commerce/fees': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Fees'
    }
  },
  '/crm/commerce/invoices': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Invoices'
    }
  },
  '/crm/commerce/orders': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Orders'
    }
  },
  '/crm/commerce/payments': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Commerce Payments'
    }
  },
  '/crm/commerce/quotes': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Quotes',
      postmanCollectionId: '9a45b2a3a1488cb66931'
    }
  },
  '/crm/commerce/subscriptions': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Commerce Subscriptions'
    }
  },
  '/crm/commerce/taxes': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Taxes',
      postmanCollectionId: '9a45b2a3a1488cb66931'
    }
  },
  '/crm/contacts-timeline-embed': {
    legacyVersions: [{
      url: '/reference/api/crm/contacts-timeline-embed/v1',
      versionName: 'v1',
      isDefaultSelected: true
    }]
  },
  '/crm/engagements/calls': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Calls',
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/engagements/communications': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Communications'
    }
  },
  '/crm/engagements/email': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Emails',
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/engagements/engagement-details': {
    legacyVersions: [{
      url: '/reference/api/crm/engagements/engagement-details/v1',
      versionName: 'v1',
      intro: 'Use the engagements API to manage notes, emails, tasks, meetings, and calls on CRM records.',
      isDefaultSelected: true,
      useCase: 'You want to upgrade your integration to log calls and meetings to contact records to streamline the sales process.',
      relatedDocuments: {
        href: '/guides/api/crm/engagements/engagement-details',
        title: 'Engagements overview'
      }
    }]
  },
  '/crm/engagements/meetings': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Meetings',
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/engagements/notes': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Notes',
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/engagements/postal-mail': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Postal Mail'
    }
  },
  '/crm/engagements/tasks': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Tasks',
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/exports': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Exports'
    }
  },
  '/crm/extensions/calling-sdk': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Calling Extensions',
      postmanCollectionId: 'b768e64f0ba435fdb59e'
    }
  },
  '/crm/extensions/crm-cards': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Public App Crm Cards',
      postmanCollectionId: '77b1d2834b0387a286be'
    }
  },
  '/crm/extensions/extension-details': {
    legacyVersions: [{
      url: '/reference/api/crm/extensions/extension-details/v1',
      versionName: 'v1',
      intro: 'Use the CRM extensions API to create custom CRM cards that can display data on CRM records.',
      isDefaultSelected: true,
      useCase: 'You want to display information from Jira tickets on relevant contact records so that customer support reps can access them directly while working with those customers.',
      relatedDocuments: {
        href: '/guides/apps/extensions/overview',
        title: 'Extensions overview'
      }
    }]
  },
  '/crm/extensions/timeline': {
    legacyVersions: [{
      url: '/reference/api/crm/extensions/timeline/v1',
      versionName: 'v1',
      intro: "Use the timeline events API to add custom event information to CRM record timelines. You'll first need to create a public app, then an event template to display event data.",
      useCase: 'After setting up a new set of sign-up flows on your website, update contact timelines to indicate which flow they completed. In each event, you could also include a link to open an iframe that displays additional external data about the event.'
    }],
    spec: {
      group: 'CRM',
      name: 'Timeline',
      postmanCollectionId: 'e5a01a5644f5e061f744'
    }
  },
  '/crm/extensions/video-conferencing': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Video Conferencing Extension',
      postmanCollectionId: '26126890-8f5582df-b553-403f-b001-6fe4cbafe4e4'
    }
  },
  '/crm/imports': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Imports',
      postmanCollectionId: 'f30b00bcfdc29170b2e8'
    }
  },
  '/crm/limits-tracking': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Limits Tracking'
    }
  },
  '/crm/lists': {
    legacyVersions: [{
      url: '/reference/api/crm/lists/v1-contacts',
      versionName: 'v1-contacts',
      intro: 'Use the lists API to manage list memberships for contact lists.',
      useCase: 'You want to bulk add contacts to a particular list.',
      alert: {
        text: "The v1 lists API will be sunset on May 30, 2025. It's strongly recommended to [migrate over to use the v3 lists API](/guides/api/crm/lists/overview#migrate-from-v1-to-v3-api-endpoints) instead. Learn more by checking out the [official announcement](https://developers.hubspot.com/changelog/upcoming-sunset-v1-lists-api) on the HubSpot Developer Changelog."
      }
    }],
    spec: {
      group: 'CRM',
      name: 'Lists'
    }
  },
  '/crm/objects/companies': {
    legacyVersions: [{
      url: '/reference/api/crm/objects/companies/v2',
      versionName: 'v2',
      intro: 'Use the companies API to create and manage CRM records that represent the companies and organizations that interact with your business.',
      useCase: 'When a new customer signs up on your website with their business email address, create a company record to represent the relationship between the customer and the company they work for. A sales rep can later use this company record for outreach to find other potential sales opportunities.'
    }, {
      url: '/reference/api/crm/objects/companies/v1',
      versionName: 'v1',
      intro: 'Use the companies API to create and manage CRM records that represent the companies and organizations that interact with your business.',
      useCase: 'When a new customer signs up on your website with their business email address, create a company record to represent the relationship between the customer and the company they work for. A sales rep can later use this company record for outreach to find other potential sales opportunities.'
    }],
    spec: {
      group: 'CRM',
      name: 'Companies',
      postmanCollectionId: 'caf616003f52c955b5f6'
    }
  },
  '/crm/objects/contacts': {
    legacyVersions: [{
      url: '/reference/api/crm/objects/contacts/v1',
      versionName: 'v1',
      intro: 'Use the contact lists API to create and manage contact lists.',
      useCase: 'Retrieve a contact list by ID to bring that contact data into your external system.'
    }],
    spec: {
      group: 'CRM',
      name: 'Contacts',
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/objects/deal-splits': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Deal Splits'
    }
  },
  '/crm/objects/deals': {
    legacyVersions: [{
      url: '/reference/api/crm/objects/deals/v1',
      versionName: 'v1',
      intro: 'Use the deals API to create and manage CRM records that represent transactions with contacts and/or companies. Deals progress through pipeline stages to reflect whether the transaction has been completed, is active, or has otherwise been lost.',
      useCase: 'When a sales rep is negotiating a potential purchase with a prospective customer, create a deal record to track the negotiation process. When the customer later makes their purchase, you can update the deal to a closed stage.'
    }],
    spec: {
      group: 'CRM',
      name: 'Deals',
      postmanCollectionId: '8b5f74a661b06f5a54bd'
    }
  },
  '/crm/objects/feedback-submissions': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Feedback Submissions'
    }
  },
  '/crm/objects/goals': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Goal Targets'
    }
  },
  '/crm/objects/leads': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Leads'
    }
  },
  '/crm/objects/line-items': {
    legacyVersions: [{
      url: '/reference/api/crm/objects/line-items/v1',
      versionName: 'v1',
      intro: 'Use the line items API to add instances of products to deals and quotes. You can create line items from existing products or create them as custom line items with no product attached.',
      useCase: 'When creating a set of quotes for sales reps to send to potential buyers, use this API to add goods and services to the quotes.'
    }],
    spec: {
      group: 'CRM',
      name: 'Line Items',
      postmanCollectionId: 'c6e6832eb67946d66554'
    }
  },
  '/crm/objects/object-library': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Object Library'
    }
  },
  '/crm/objects/objects': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Objects',
      postmanCollectionId: '40a2452fc88665fbe786'
    }
  },
  '/crm/objects/partner-clients': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Partner Clients'
    }
  },
  '/crm/objects/products': {
    legacyVersions: [{
      url: '/reference/api/crm/objects/products/v1',
      versionName: 'v1',
      intro: 'Use the products API to create and manage your product library, which is the collection of goods and services that your company offers. Products in your product library can be used as line items when creating deals, payment links, invoices, and quotes.',
      useCase: 'To prepare for an upcoming product launch, use the products API to create a set of products to represent your new offerings. Sales reps can then use these products when creating payment links, invoices, and more.'
    }],
    spec: {
      group: 'CRM',
      name: 'Products',
      postmanCollectionId: 'b4ad6241d1138c449a57'
    }
  },
  '/crm/objects/schemas': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Schemas'
    }
  },
  '/crm/objects/tickets': {
    legacyVersions: [{
      url: '/reference/api/crm/objects/tickets/v1',
      versionName: 'v1',
      intro: 'Use the tickets API to create and manage CRM records that represent customer service requests in your CRM.',
      useCase: 'When a customer contacts your support team to request help with an issue, create a ticket to track that request and keep a record of it for future reference.'
    }],
    spec: {
      group: 'CRM',
      name: 'Tickets',
      postmanCollectionId: '26126890-98d91102-ed47-41b0-8304-23f04f12ed0f'
    }
  },
  '/crm/owners': {
    legacyVersions: [{
      url: '/reference/api/crm/owners/v2',
      versionName: 'v2',
      alert: {
        text: "The v2 owners API will be sunset on March 24, 2025. It's strongly recommended you migrate over to use the v3 owners API instead. Learn more by checking out the [official announcement](https://developers.hubspot.com/changelog/final-reminder-sunset-of-v2-owners-api) on the HubSpot Developer Changelog."
      }
    }],
    spec: {
      group: 'CRM',
      name: 'Crm Owners',
      postmanCollectionId: '78f10c037170a05f3b3a'
    }
  },
  '/crm/pipelines': {
    legacyVersions: [{
      url: '/reference/api/crm/pipelines/v1',
      versionName: 'v1'
    }],
    spec: {
      group: 'CRM',
      name: 'Pipelines',
      postmanCollectionId: '2c67a53481327b3678ec'
    }
  },
  '/crm/properties': {
    legacyVersions: [{
      url: '/reference/api/crm/properties/v2',
      versionName: 'v2',
      intro: 'Use the properties API to store information in fields on individual CRM records, such as contacts. You can use this API to manage existing default and custom properties, create new custom properties, and more.',
      useCase: 'Create a set of custom multi-select properties for storing contract data on company records.'
    }, {
      url: '/reference/api/crm/properties/v1-deals',
      versionName: 'v1-deals'
    }, {
      url: '/reference/api/crm/properties/v1-contacts',
      versionName: 'v1-contacts'
    }, {
      url: '/reference/api/crm/properties/v1-companies',
      versionName: 'v1-companies'
    }],
    spec: {
      group: 'CRM',
      name: 'Properties',
      postmanCollectionId: '33493e154afc38038092'
    }
  },
  '/crm/property-validations': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Property Validations'
    }
  },
  '/deprecated/social-media': {
    legacyVersions: [{
      url: '/reference/api/deprecated/social-media/v1',
      versionName: 'v1',
      isDefaultSelected: true
    }]
  },
  '/library/files': {
    legacyVersions: [],
    spec: {
      group: 'Files',
      name: 'Files'
    }
  },
  '/library/meetings': {
    legacyVersions: [],
    spec: {
      group: 'Scheduler',
      name: 'Meetings'
    }
  },
  '/marketing/campaigns': {
    legacyVersions: [],
    spec: {
      group: 'Marketing',
      name: 'Campaigns Public Api',
      postmanCollectionId: 'v=26126890-c8e3f08f-8fa9-4a9f-9929-bfa7964ceb21'
    }
  },
  '/marketing/emails/marketing-emails': {
    legacyVersions: [{
      url: '/reference/api/marketing/emails/marketing-emails/v1',
      versionName: 'v1',
      intro: 'Use the marketing email API to create, manage, and retrieve details about marketing emails. You can also this use API to retrieve statistics related to email sends, similar to what you can see in in HubSpot.',
      useCase: 'You want to programmatically create marketing emails, then retrieve their send statistics at a later time.',
      relatedDocuments: {
        href: '/guides/api/marketing/emails/marketing-emails',
        title: 'Marketing email overview'
      }
    }],
    spec: {
      group: 'Marketing',
      name: 'Marketing Emails'
    }
  },
  '/marketing/emails/single-send-api': {
    legacyVersions: [],
    spec: {
      group: 'Marketing',
      name: 'Single-send'
    }
  },
  '/marketing/emails/transactional-emails': {
    legacyVersions: [{
      url: '/reference/api/marketing/emails/transactional-emails/v1',
      versionName: 'v1',
      intro: 'Use the transactional email API to send emails from a dedicated IP address to your contacts for essential business transactions, including account updates or terms of service changes.',
      useCase: 'After a customer converts or purchases a product from your business, you want to send them a receipt of their transaction.'
    }],
    spec: {
      group: 'Marketing',
      name: 'Transactional Single Send'
    }
  },
  '/marketing/forms': {
    legacyVersions: [{
      url: '/reference/api/marketing/forms/v3-legacy',
      versionName: 'v3-legacy',
      intro: 'Use the forms API to create and manage forms in your HubSpot account, which you can then embed on your website to collect form submissions from website visitors.',
      useCase: "You want to programmatically change certain parts of a HubSpot form based on more complex use-cases or data from a third-party service (displaying a field dynamically based on data you're querying from an external source)."
    }, {
      url: '/reference/api/marketing/forms/v2',
      versionName: 'v2',
      intro: 'Use the forms API to create and manage forms in your HubSpot account, which you can then embed on your website to collect form submissions from website visitors. This version of the forms API incldues endpoints for submitting form data',
      useCase: "You want to programmatically change certain parts of a HubSpot form based on more complex use-cases or data from a third-party service (displaying a field dynamically based on data you're querying from an external source)."
    }, {
      url: '/reference/api/marketing/forms/v1',
      versionName: 'v1',
      intro: 'Use the forms API to create and manage forms in your HubSpot account, which you can then embed on your website to collect form submissions from website visitors.',
      useCase: "You want to programmatically change certain parts of a HubSpot form based on more complex use-cases or data from a third-party service (displaying a field dynamically based on data you're querying from an external source)."
    }],
    spec: {
      group: 'Marketing',
      name: 'Forms'
    }
  },
  '/marketing/marketing-events': {
    legacyVersions: [{
      url: '/reference/api/marketing/marketing-events/v1',
      versionName: 'v1'
    }],
    spec: {
      group: 'Marketing',
      name: 'Marketing Events',
      postmanCollectionId: '26126890-66991673-f7f9-4430-8c49-ddd3b01141b9'
    }
  },
  '/marketing/subscriptions': {
    legacyVersions: [{
      url: '/reference/api/marketing/subscriptions/v1',
      versionName: 'v1',
      intro: 'The subscription preferences APIs allow you to retrieve and manage data on the communications that a contact has opted into.',
      useCase: 'You use a third-party service to send marketing emails or other communications to your contacts, and you want to ensure proper compliance on sending to contacts who have provided explicit consent.'
    }],
    spec: {
      group: 'Communication Preferences',
      name: 'Subscriptions'
    }
  },
  '/settings/account-activity-api': {
    legacyVersions: [{
      url: '/reference/api/settings/account-activity-api/v1',
      versionName: 'v1',
      intro: 'The account activity API allows you to retrieve login history and security activity for your HubSpot account.',
      useCase: 'You want to build an internal tool for your compliance team to regularly monitor the activity of employees in your company accessing data in your HubSpot account.'
    }],
    spec: {
      group: 'Account',
      name: 'Audit Logs'
    }
  },
  '/settings/account-information-api': {
    legacyVersions: [],
    spec: {
      group: 'Account',
      name: 'Account Info'
    }
  },
  '/settings/business-units-api': {
    legacyVersions: [],
    spec: {
      group: 'Business Units',
      name: 'Business Units'
    }
  },
  '/settings/currencies': {
    legacyVersions: [],
    spec: {
      group: 'Settings',
      name: 'Multicurrency'
    }
  },
  '/settings/users/user-details': {
    legacyVersions: [],
    spec: {
      group: 'CRM',
      name: 'Users'
    }
  },
  '/settings/users/user-provisioning': {
    legacyVersions: [],
    spec: {
      group: 'Settings',
      name: 'User Provisioning'
    }
  }
};