import { useEffect, useState } from 'react';
import { populateLegacyToc } from '../components/apiReference/util';
import { populateToc } from '../components/apiReference/util';
import isEqual from 'hs-lodash/isEqual';
const useToc = ({
  loadingStatus,
  oasDocs,
  version,
  mdx
}) => {
  const [toc, setToc] = useState();
  const [switchToc, setSwitchToc] = useState(false);
  useEffect(() => {
    // If the version is legacy and there is no mdx, don't set the toc
    if (version !== null && version !== void 0 && version.isLegacy && !mdx) {
      return;
    }
    if (!toc && oasDocs && loadingStatus.succeeded) {
      setToc(populateToc(oasDocs));
    }

    // Slightly janky way to reset the value of the ToC when switching routes
    if (!(version !== null && version !== void 0 && version.isLegacy) && toc && loadingStatus.pending) {
      setToc(undefined);
    }
    if (!toc && loadingStatus.succeeded) {
      setToc(populateToc(oasDocs));
    }
    if (version !== null && version !== void 0 && version.isLegacy && (!toc || switchToc)) {
      const legacyMdx = mdx;
      const newToc = populateLegacyToc(legacyMdx === null || legacyMdx === void 0 ? void 0 : legacyMdx.title, legacyMdx === null || legacyMdx === void 0 ? void 0 : legacyMdx.orderedTitles, legacyMdx === null || legacyMdx === void 0 ? void 0 : legacyMdx.orderedHttpMethods, !!(version !== null && version !== void 0 && version.intro && version !== null && version !== void 0 && version.useCase));

      // If there is no existing toc or the new toc is different, set the new toc
      if (!toc || !isEqual(newToc, toc)) {
        setToc(newToc);
        setSwitchToc(false);
      }
    }
  }, [loadingStatus, mdx, oasDocs, switchToc, toc, version]);
  return {
    toc,
    setSwitchToc
  };
};
export default useToc;