import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
const useServerSideRedirect = ({
  isPublicDocs = false,
  locale,
  pathname
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(isPublicDocs);
  const storageKey = `${locale}-reloadCount${pathname}`;
  const handleReloadCount = useCallback(() => {
    if (!isPublicDocs) return;
    const reloadCount = parseInt(sessionStorage.getItem(storageKey) || '0', 10);
    if (reloadCount === 0) {
      // Reload the page to remount the SPA and hit any s-s redirects
      sessionStorage.setItem(storageKey, String(reloadCount + 1));
      history.go(0);
    } else if (reloadCount === 1) {
      // Remove session storage key for this path
      sessionStorage.removeItem(storageKey);
      setLoading(false);
    }
  }, [history, isPublicDocs, storageKey]);
  useEffect(() => {
    handleReloadCount();
  }, [handleReloadCount, isPublicDocs]);
  return {
    loading
  };
};
export default useServerSideRedirect;