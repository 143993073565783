import { topLevelDirectories, urlKeys } from 'developer-docs-source/urlKeys';
export const GUIDES_PREFIX = 'guides';
export const REFERENCE_PREFIX = 'reference';
export const GETTING_STARTED_PREFIX = 'getting-started';
export const API_GUIDES_PREFIX = `${GUIDES_PREFIX}/api`;
export const API_REFERENCE_PREFIX = `${REFERENCE_PREFIX}/api`;
import merge from 'hs-lodash/merge';
export const RATE_LIMIT_DOCUMENTATION = 'https://developers.hubspot.com/docs/api/usage-details#rate-limits';
const enUsKeys = urlKeys['en-us'];
export const getFullCtxKey = (section, doc) => `./${section}/${doc}`;
const keyToAbsolutePath = key => `.${key}`;
const isSection = section => {
  return topLevelDirectories.includes(section);
};
export const isValidDocForLocale = (doc, section, locale) => {
  if (section === 'reference/api') {
    return urlKeys['en-us'][section].spec.includes(doc) || urlKeys[locale][section].mdx.includes(doc);
  }
  return urlKeys[locale][section].includes(doc);
};
export const parseContextKeyForLocale = (key, locale) => {
  const cleanedKey = key.startsWith('./') ? key.slice(2) : key;

  // Dynamically create a regex pattern from topLevelDirectories
  const directoryPattern = topLevelDirectories.join('|');
  const regex = new RegExp(`^(${directoryPattern})/?(.*)$`);
  const match = cleanedKey.match(regex);
  if (match) {
    const [, section, doc] = match;
    if (isSection(section) && isValidDocForLocale(doc, section, locale)) {
      return {
        section,
        doc: doc || ''
      };
    }
  }
  return {
    section: undefined,
    doc: undefined
  };
};
export const GUIDE_DOCS = new Set(enUsKeys[API_GUIDES_PREFIX].map(key => keyToAbsolutePath(`/${API_GUIDES_PREFIX}/${key}`)));
export const CMS_GUIDE_DOCS = new Set(enUsKeys['guides/cms'].map(key => keyToAbsolutePath(`/${GUIDES_PREFIX}/cms/${key}`)));
export const GET_STARTED_DOCS = new Set(enUsKeys[GETTING_STARTED_PREFIX].map(key => keyToAbsolutePath(`/${GETTING_STARTED_PREFIX}/${key}`)));
export const SUPPORTED_CRM_CUSTOMIZATION_DOCS = new Set(enUsKeys['guides/crm'].map(key => keyToAbsolutePath(`/guides/crm/${key}`)));
export const UI_COMPONENTS_DOCS = new Set(enUsKeys['reference/ui-components'].map(key => keyToAbsolutePath(`/reference/ui-components/${key}`)));
const APPS_DOCS = new Set(enUsKeys['guides/apps'].map(key => keyToAbsolutePath(`/guides/apps/${key}`)));
const CMS_REFERENCE_DOCS = new Set(enUsKeys['reference/cms'].map(key => keyToAbsolutePath(`/reference/cms/${key}`)));
export const API_MDX_DOCS = new Set(enUsKeys[API_REFERENCE_PREFIX]['mdx'].map(key => keyToAbsolutePath(`/${API_REFERENCE_PREFIX}/${key}`)));
export const API_SPEC_DOCS = new Set(enUsKeys[API_REFERENCE_PREFIX]['spec'].map(key => keyToAbsolutePath(`/${API_REFERENCE_PREFIX}/${key}`)));
export const ctxKeyToPath = ctxKey => {
  return ctxKey.replace('.', '').replace(/\$/, '');
};
export const guidesCtxKeyToPath = ctxKey => {
  return ctxKey.replace('.', '/guides').replace(/\$/, '');
};
export const docPathToCtxKey = docsPath => {
  return `${docsPath.replace(/^\/doc/, '.')}`;
};
const returnWithProtectedDocs = (ctxKey, gateProtected, topLevelDirectory) => {
  const whitelistedUrlKeys = merge(urlKeys, gateProtected);
  const WITH_PROTECTED_DOCS = new Set(whitelistedUrlKeys['en-us'][topLevelDirectory].map(key => keyToAbsolutePath(`/${topLevelDirectory}/${key}`)));
  return WITH_PROTECTED_DOCS.has(ctxKey);
};
export const isGuideDoc = (ctxKey, gateProtected) => {
  if (gateProtected && gateProtected['en-us']['guides/api']) {
    return returnWithProtectedDocs(ctxKey, gateProtected, 'guides/api');
  }
  return GUIDE_DOCS.has(ctxKey);
};
export const isCMSDevelopmentDoc = (ctxKey, gateProtected) => {
  if (gateProtected && gateProtected['en-us']['guides/cms']) {
    return returnWithProtectedDocs(ctxKey, gateProtected, 'guides/cms');
  }
  return CMS_GUIDE_DOCS.has(ctxKey);
};
export const isGettingStartedDoc = (ctxKey, gateProtected) => {
  if (gateProtected && gateProtected['en-us']['getting-started']) {
    return returnWithProtectedDocs(ctxKey, gateProtected, 'getting-started');
  }
  return GET_STARTED_DOCS.has(ctxKey);
};
export const isCRMCustomizationDoc = (ctxKey, gateProtected) => {
  if (gateProtected && gateProtected['en-us']['guides/crm']) {
    return returnWithProtectedDocs(ctxKey, gateProtected, 'guides/crm');
  }
  return SUPPORTED_CRM_CUSTOMIZATION_DOCS.has(ctxKey);
};
export const isUIComponentsDoc = (ctxKey, gateProtected) => {
  if (gateProtected && gateProtected['en-us']['reference/ui-components']) {
    return returnWithProtectedDocs(ctxKey, gateProtected, 'reference/ui-components');
  }
  return UI_COMPONENTS_DOCS.has(ctxKey);
};
export const isAppsDoc = (ctxKey, gateProtected) => {
  if (gateProtected && gateProtected['en-us']['guides/apps']) {
    return returnWithProtectedDocs(ctxKey, gateProtected, 'guides/apps');
  }
  return APPS_DOCS.has(ctxKey);
};
export const isCmsReferenceDoc = (ctxKey, gateProtected) => {
  if (gateProtected && gateProtected['en-us']['reference/cms']) {
    return returnWithProtectedDocs(ctxKey, gateProtected, 'reference/cms');
  }
  return CMS_REFERENCE_DOCS.has(ctxKey);
};
export const isSupportedApiMdxDoc = (ctxKey, gateProtected) => {
  if (gateProtected && gateProtected['en-us']['reference/api']) {
    return returnWithProtectedDocs(ctxKey, gateProtected, 'reference/api');
  }
  return API_MDX_DOCS.has(ctxKey);
};
export const isMdxDoc = ctxKey => {
  return isGuideDoc(ctxKey) || isCMSDevelopmentDoc(ctxKey) || isGettingStartedDoc(ctxKey) || isCRMCustomizationDoc(ctxKey) || isUIComponentsDoc(ctxKey) || isAppsDoc(ctxKey) || isCmsReferenceDoc(ctxKey) || isSupportedApiMdxDoc(ctxKey);
};
export const getQueryParam = searchParamKey => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(searchParamKey);
};