import I18n from 'I18n';
import { addLabelAndVerifyBadge } from '../navUtils';
import { PageTypes, Paths } from '../../../components/RoutesPublic';
import memoize from 'react-utils/memoize';
import { navigation } from 'developer-docs-source/navigation';

/**
 * 'Guides' > 'Apps' nav config.
 */
export const appsNavConfig = memoize(() => {
  const commonPath = `${Paths.GUIDES}/apps`;
  const nav = navigation[commonPath];
  const navigationItems = nav.items.map(item => addLabelAndVerifyBadge(item));
  const config = {
    locatorPageType: PageTypes.GUIDES,
    hasMdxOverview: true,
    sectionLabel: I18n.text('developer-docs-ui.navigation.guides.apps'),
    commonPath,
    items: navigationItems
  };
  return config;
});