import { legacyDocsIntroContent, legacyDocsDeprecatedContent } from 'developer-docs-shared-components/config/versionInfo';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSpecInfo } from '../selectors/allSpecs';
import { useParams } from 'react-router-dom';
import { versionInfo } from 'developer-docs-source/navigation';
import { apiEndpointsNavConfig } from '../config/nav/reference/apiEndpoints';
import { getSpecOverrideUrl } from '../utils/urls';
const getLegacyAlert = (path, legacyAlert) => {
  if (legacyAlert) {
    return {
      text: legacyAlert.text,
      type: legacyAlert.type || 'warning'
    };
  }
  return path.includes('/deprecated') ? legacyDocsDeprecatedContent() : legacyDocsIntroContent();
};

/**
 * Combines legacy and non-legacy version info for a given API.
 * The legacy versions are fetched from the static `legacyVersionInfo` object.
 * The non-legacy versions are fetched from the Redux store.
 * @param path The url we want to get version info for
 * @returns An array of version info objects, both legacy and non-legacy
 */
export const useGetVersionInfo = path => {
  var _versionInfo$cleanedP, _versionInfo$cleanedP2, _versionInfo$cleanedP3;
  const apiNavConfig = apiEndpointsNavConfig();
  const cleanedPath = path.replace(apiNavConfig.commonPath, '');
  const spec = (_versionInfo$cleanedP = versionInfo[cleanedPath]) === null || _versionInfo$cleanedP === void 0 ? void 0 : _versionInfo$cleanedP.spec;
  const versionNumberOverride = (_versionInfo$cleanedP2 = versionInfo[cleanedPath]) === null || _versionInfo$cleanedP2 === void 0 ? void 0 : _versionInfo$cleanedP2.versionNumberOverride;
  const versionNameOverride = (_versionInfo$cleanedP3 = versionInfo[cleanedPath]) === null || _versionInfo$cleanedP3 === void 0 ? void 0 : _versionInfo$cleanedP3.versionNameOverride;
  const specInfo = useSelector(selectSpecInfo(spec === null || spec === void 0 ? void 0 : spec.group, spec === null || spec === void 0 ? void 0 : spec.name));
  const legacyVersions = useMemo(() => {
    var _versionInfo$cleanedP4;
    return ((_versionInfo$cleanedP4 = versionInfo[cleanedPath]) === null || _versionInfo$cleanedP4 === void 0 ? void 0 : _versionInfo$cleanedP4.legacyVersions.map(v => {
      return Object.assign({}, v, {
        isLegacy: true,
        alert: getLegacyAlert(cleanedPath, v.alert)
      });
    })) || [];
  }, [cleanedPath]);
  const {
    versionId
  } = useParams();
  const versions = useMemo(() => {
    if (!spec) return legacyVersions;
    if (versionNumberOverride && versionNameOverride) {
      // I think there should not be legacyVersions for an alpha version
      const res = legacyVersions.concat([{
        name: spec.name,
        group: spec.group,
        openApi: getSpecOverrideUrl(spec.name, spec.group, versionNumberOverride),
        versionName: versionNameOverride,
        isDefaultSelected: true,
        isLegacy: false
      }]);
      return res;
    }
    if (!specInfo) return [];
    const isLegacyDefaultSelected = legacyVersions.some(v => v.isDefaultSelected);
    const nonLegacyVersions = specInfo.versions.map(v => ({
      name: spec.name,
      group: spec.group,
      openApi: v.openApi,
      versionName: `v${v.version}`,
      isDefaultSelected: v.stage === 'LATEST' && !isLegacyDefaultSelected,
      isLegacy: false,
      introContent: {
        intro: v.introduction,
        useCase: v.useCase,
        postmanCollectionId: spec.postmanCollectionId,
        relatedDocumentation: v.relatedDocumentation,
        documentationBanner: v.documentationBanner,
        requirements: v.requirements
      },
      version: v.version
    })).sort((a, b) => b.version - a.version);
    return nonLegacyVersions.concat(legacyVersions);
  }, [spec, legacyVersions, specInfo, versionNumberOverride, versionNameOverride]);
  const version = useMemo(() => versions.find(v => v.versionName === versionId) ||
  // If versioning is not enabled, lookup the default version from versions.
  // TODO: Remove this once versioning is enabled across the board.
  versions.find(v => v.isDefaultSelected) || undefined, [versionId, versions]);
  return {
    versions,
    version
  };
};