import enviro from 'enviro';
// @ts-expect-error Untyped
// TODO: determine if it is ok to use this shared httpClient here
import http from 'developer-docs-shared-components/lib/utils/httpClient';
/**
 * Google Programmable Search Engine (PSE) keys for each locale. Each key is
 * scoped to the ccTLD's `docs/*` path to which its `*.developers.hubspot.*`
 * proxy resolves.
 *
 * Search Engine keys can be added or edited via the Google PSE control panel.
 * If you need access, ask your TL to add you as an admin to each engine.
 *
 * @see https://programmablesearchengine.google.com/controlpanel/all
 */
const googleEngines = {
  'de-de': '66b22fb523589465c',
  'en-us': 'a2fc2d641bce84ce4',
  'es-es': 'c55c06a8ee5044544',
  'fr-fr': '01fd68f3fabd542e2',
  'ja-jp': '85d6cb9e6739c4cd1',
  'pt-br': '016f561d5b45f4d8d'
};

/** ccTLD-specific Google Programmable Search Engine key. */

/**
 * The Google Custom Search (now called "Google Programmable Search") endpoint
 * hosted by the Web Team. Each locale has its own search engine filtered on
 * results across its respective ccTLD's `docs/*` path, where the
 * `*.developers.hubspot.*` proxy resolves.
 *
 * @example 'https://wtcfns.hubspot.com/qa-custom-search/a2fc2d641bce84ce4'
 *
 * @see https://git.hubteam.com/MarketingWebTeam/webteam-api-custom-search
 */
export const getGoogleSearchRootURL = locale => `https://wtcfns.hubspot.com/${enviro.isQa() ? 'qa-' : ''}custom-search/${googleEngines[locale]}`;

/**
 * A search result returned by the Google Programmable Search endpoint.
 * Eventually badges will be derived via metadata, once indexed.
 */

/**
 * Badge of the page, currently set according to the primary directory or,
 * if none is available, `'index'`.
 */

/**
 * Object of meta tag provided by the Google search response within
 * `pagemap.metatags[0]`.
 */

/**
 * @internal
 * Properties defined by Google that are not used in app.
 */

/**
 * Response object returned by the Google Custom/Programmable Search endpoint
 * with an array of `items` and an optional `start` offset string.
 */

/**
 * Fetch search results via Google Programmable Search endpoint.
 */
export function fetchSearchResults(sanitizedEncodedQuery, locale, offset = 1) {
  return http.get(`${getGoogleSearchRootURL(locale)}?query=${sanitizedEncodedQuery}&start=${offset}&sort=date:d:s`);
}