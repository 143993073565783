import { getLabel } from 'developer-docs-source/labels/index';
export const getBreadcrumbLabelsFromPath = (path, primaryDir, secondaryDir) => {
  // get all path parts except the last part which is the title of the page
  const pathParts = path.split('/').slice(0, -1).filter(part => part !== '');

  // get all partials paths for the current path
  // e.g. first entry is /{pathParts[0]}, second entry is /{pathParts[0]}/{pathParts[1]}
  const pathEntries = pathParts.reduce((acc, part, index) => {
    if (index === 0) {
      acc.push(`/${primaryDir}/${secondaryDir}`);
      if (part !== secondaryDir) {
        acc[0] = `${acc[0]}/${part}`;
      }
    } else {
      acc.push(`${acc[index - 1]}/${part}`);
    }
    return acc;
  }, []);
  return pathEntries.map(entry => ({
    name: getLabel(entry)
  }));
};

/**
 * extracts the key portion from the URL e.g. /reference/api/contacts/v3#tag -> /reference/api/contacts
 * @param url - The URL to extract the key from.
 * @returns A key that corresponds to a key in `keyToNavData`.
 */
export function getKeyFromUrl(url) {
  // the url followed by an optional '/v[number]' where number is one or more digits, and an optional '#...' part.
  // The '?' after the version and tag sections makes them non-capturing groups.
  const regex = /(.*?)(?:\/v\d+|#|$)/;
  const match = url.match(regex);
  // If there was a match, return the first capturing group (the part of the URL we're interested in).
  // If there was no match, return the original URL.
  // The replace function is used to remove the version and tag sections from the match.
  return match ? match[1].replace(/(\/v\d+|#.*)$/, '') : url;
}