import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
import { proxiedSubdomainBasePath } from 'developer-docs-shared-components/utils/urls';
import { getPathNames } from 'developer-docs-shared-components/utils/urls';
import getEnv from './getEnv';
import memoizeOne from 'react-utils/memoizeOne';
import { getSubdomainFromLocale } from 'developer-docs-shared-components/lib/locale/utils';
const getBaseUrl = () => '/docs';
const getPublicBaseUrl = () => {
  const env = getEnv();
  if (env === 'local' || !!window.location.hostname.match(/app\.hubspot(qa)?\.com/)) {
    const {
      location: {
        pathname
      }
    } = window;
    const {
      primaryDir
    } = getPathNames(pathname);
    return primaryDir;
  } else if (window.location.hostname.match(/developers\.(wt)?hubspot\.(com|de|es|fr|jp)/)) {
    return proxiedSubdomainBasePath;
  } else {
    return '';
  }
};

/**
 * Gets English URL given an absolute path.
 */
export const getEnglishBaseUrl = memoizeOne(absolutePath => {
  const env = getEnv();
  return env === 'local' ? `https://local.hubteamqa.com/developer-docs-app-en-us${absolutePath}` : `https://developers.${env === 'qa' ? 'wt' : ''}hubspot.com${proxiedSubdomainBasePath}${absolutePath}`;
});
export const getLanguageUrl = (locale, path, /** Environment to use. Defaults to current environment. */
env = getEnv()) => {
  return env === 'local' ? `https://local.hubteamqa.com/developer-docs-app-${locale}${path}` : `${getSubdomainFromLocale(locale, env === 'qa')}${proxiedSubdomainBasePath}${path}`;
};
export const getRootUrl = () => `${getBaseUrl()}/${PortalIdParser.get()}`;
export const getPublicRootUrl = () => getPublicBaseUrl();
export const getDashboardRedirectUrl = () => `${getFullUrl('app')}/dashboard/${PortalIdParser.get()}`;
export const getDeveloperHomeRedirectUrl = () => `${getFullUrl('app')}/developer-home/${PortalIdParser.get()}`;
export const getEnglishSearchResultsUrl = query => getEnglishBaseUrl(`/search?q=${query}`);

/**
 * The full app LB home URL, which displays the login form when anonymous
 * or the list of a user's portals when authed.
 */
export const getAppHomeUrl = () => `${getFullUrl('app')}/home`;
export const getLoginUrl = () => `${getFullUrl('app')}/login`;

// TODO: determine if this redirect can handle lang-specific re-directs
// currently, it appears that the developers.hubspot search results page is
// not supportive of all the langs that the docs support.
export const getSearchRedirectUrl = query => {
  return query ? `https://developers.hubspot.com/hs-search-results?term=${query}` : 'https://developers.hubspot.com';
};
export const isVersionedPath = path => {
  // use regex to see if URL is a reference API url and ends in /v1, /v2, /v3, or /v3-legacy, /v1-contacts etc
  // and optionally followed by query parameters
  return /\/v\d+(-\w+)?(\/?(\?.*)?)?$/.test(path);
};
export const getSpecOverrideUrl = (name, group, version) => {
  return `https://api.hubspot.com/public/api/spec/v1/specs/${group}/${name}/versions/${version}`;
};