import I18n from 'I18n';
import { navigation } from 'developer-docs-source/navigation';
import memoize from 'react-utils/memoize';
import { PageTypes, Paths } from '../../../components/RoutesPublic';
import { addLabelAndVerifyBadge } from '../navUtils';

/**
 * 'Guides' > 'CRM Customization' nav config.
 *
 * Hard-coded data for the guides left hand navigation. Currently, labels and
 * breadcrumbs for guides are based off of the same key used for the API
 * Reference endpoints pages and align with a partial chunk of the guides urls.
 * While we are hardcoding these keys for now (see label property) below, we may
 * need to consider how we will determine Guides keys from the BE in the long run
 */

export const crmCustomizationNavConfig = memoize(() => {
  const commonPath = `${Paths.GUIDES}/crm`;
  const nav = navigation[commonPath];
  const navigationItems = nav.items.map(item => addLabelAndVerifyBadge(item));
  const config = {
    locatorPageType: PageTypes.GUIDES,
    commonPath,
    badge: 'BETA',
    hasMdxOverview: true,
    sectionLabel: I18n.text('developer-docs-ui.navigation.guides.crmCustomization'),
    items: navigationItems
  };
  return config;
});