/**
 * Given a `use` string, returns its color hex string.
 */
export const getBadgeColorByMethodName = use => {
  switch (use.toLowerCase()) {
    case 'get':
      return 'thunderdome';
    case 'post':
      return 'calypso';
    case 'delete':
      return 'candy-apple';
    default:
      // PUT and PATCH
      return 'default';
  }
};