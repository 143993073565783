const LEFT_MOUSE_BUTTON = 0;
export const isLeftClick = mouseEvent => {
  const {
    button,
    altKey,
    ctrlKey,
    shiftKey,
    metaKey
  } = mouseEvent;
  return button === LEFT_MOUSE_BUTTON && !(altKey || ctrlKey || shiftKey || metaKey);
};