let _ = t => t,
  _t;
import { createGlobalStyle, css } from 'styled-components';

/**
 * Queens font face declaration. Queens is currently only used on the main
 * landing page.
 */
const QueensFontStyles = createGlobalStyle(_t || (_t = _`
  @font-face {
    font-display: swap;
    font-family: "Queens Medium";
    src:
      local('Queens'),
      local('Queens Medium'),
      url("//53.fs1.hubspotusercontent-na1.net/hubfs/53/tools/fonts/Queens-Medium.woff2")
        format('woff2');
  }
`));
export const queensFontFamily = css(["font-family:'Queens Medium',Georgia,serif;"]);
export default QueensFontStyles;