import styled from 'styled-components';
export const StyledHeader = styled.div.withConfig({
  displayName: "CardHeader__StyledHeader",
  componentId: "kcl8ia-0"
})(["background:", ";border-radius:", " ", " 0 0;", ";padding:", ";"], ({
  theme
}) => theme.color['fill-primary-disabled'], ({
  theme
}) => theme.space[400], ({
  theme
}) => theme.space[400], ({
  theme
}) => theme.color['border-core-default'], ({
  theme
}) => theme.space[600]);